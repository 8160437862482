import React, { useEffect } from "react";
import { Grid, IconButton, Typography } from "@material-ui/core";
import { Translate } from "utils/Translate";
import ClearIcon from "@material-ui/icons/Clear";
import IconRigntArrowInl from "CommonImages/icon-rightArrowInline";
import config from "../../assets/config.json";
import "./Drawer.scss";
import { useDrawer } from "../Drawer";
import { useParams } from "react-router";

const CommonDrawer = (props) => {
  const params = useParams();
  const { data, handleClick, menuHeading } = props;
  const { handleDrawerClose } = useDrawer();

  const closeHandler = () => {
    handleDrawerClose();
  };

  const getTitle = () => {
    return Translate({id: `${menuHeading.context}.${ menuHeading.title }`})
  };

  useEffect(() => {
    isVisible(params.id);
  }, [document.getElementById(params.id)]);

  function isVisible(id) {
    // this function is in use to scroll category tabs
    try {
      const element = document.getElementById(id);
      const rect = element?.getBoundingClientRect();
      //check for clicked category tab is not in the view port

      if (rect.top >= 0 && rect.bottom >= window.innerHeight) {
        // scrolling to top
        document
          .querySelector(".drawer-scroll")
          .scrollTo({
            top: rect.bottom - window.innerHeight + rect.height,
            behavior: "smooth",
          });
      }
    } catch (error) {
      return () => {};
    }
  }
  return (
    <div
      className="drawer"
      onLoad={isVisible(params.id)}
      data-testid="category-drawer"
    >
      <Grid container className="drawer-title">
        <Grid item lg={8} md={8} sm={6} xs={6}>
          <Typography
            variant="h2"
            className="m-t-15"
            style={{ fontSize: "28px" }}
          >
            {getTitle()}
          </Typography>
        </Grid>
        <Grid item lg={4} md={4} sm={6} xs={6}>
          <div className="text-right">
            <IconButton
              onClick={closeHandler}
              className=""
              style={{
                paddingBottom: "0px",
                background: "none",
                marginTop: "2px",
                paddingRight: "16px",
              }}
            >
              <ClearIcon className="text-black" style={{ fontSize: "2rem" }} />
            </IconButton>
          </div>
        </Grid>
      </Grid>
      <div className="drawer-scroll">
        {data?.map((data, index) => (
          <div
            className="drawer-container"
            data-testId="collection-item"
            id={data.tag}
            onClick={() => {
              handleClick(data.tag);
              handleDrawerClose();
            }}
          >
            <Typography
              Typography
              data-testid={params.id}
              className={`makeTextClickable text ${
                params.id === data.tag?.toString() && "dynamiclinks"
              }`}
            >
              {data.name}
            </Typography>
            <IconRigntArrowInl
              color={config.primaryColor}
              cls="arrow-field arrow-size"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default CommonDrawer;
