import React, { Fragment } from "react";
import { Button, Typography } from "@material-ui/core";
import { classStarted } from '../../utils/LivestreamTime';

function CountdownButton({ startTime,occurenceDate,isStreaming, clickHandler, history }) {
    const eventStartDate = `${occurenceDate}T${startTime}`
    return (
        <Fragment>
            {/* {logger(!(new Date(startTime) < new Date() && status == "streaming"))} */}
            {(new Date(eventStartDate) < new Date() && isStreaming === true) &&
                (<Button className="view-btn bg-red"
                    color="secondary"
                    variant="contained"
                    onClick={()=>clickHandler}
                    role='button'
                    aria-label='Streaming counter'
                    tabIndex='0'
                >
                    <div className="MuiTypography-root MuiTypography-h4">
                        {classStarted(eventStartDate, isStreaming)}
                    </div>
                </Button>) 
                // : (
                //     <Button className="view-btn btn-default"
                //         color="secondary"
                //         variant="contained"
                //         onClick={()=>clickHandler}
                //         >
                //         <Typography variant="h4">
                //             {Translate({ id: "liveStream.ViewDetails" })}
                //         </Typography>
                //     </Button>
                // )
            }
        </Fragment>)
}
export default CountdownButton