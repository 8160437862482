import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import Spinner from "./components/spinner/AppLoader";
import Localytics from "./hoc/LocalyticsContainer";
import { loadPoKeys } from "./store/actions/ondemand";
import { fetchTenantConfig } from "./store/actions/tenantConfig";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import theme from "./theme/theme";
import { setAppLoadStartEvent } from "./store/actions/webPlayer";
import Router from "components/Router/Router";
import EmbeddedWrapper from "EmbeddedWrapper";
import { useRouter } from "hooks/useRouter";
import { GetSiteConfig } from "./store/actions/siteConfig.js";
import { getTenantConfig } from "store/actions/tenantConfig";
import {RoyaltyCacheTrigger} from "components/FitRadio/RoyaltyCacheTrigger";
import { initLocalytics } from "./utils/Localytics";
const AppWrapper = (props) => {
  const { webPlayerStartTime, appLoadStartTime } = props;
  const getInitials = useCallback(() => {
    webPlayerStartTime(appLoadStartTime);
  }, [webPlayerStartTime, appLoadStartTime]);
  useEffect(() => {
    getInitials();
  }, [getInitials]);

  // Initialize Localytics once we have tenant config data
  useEffect(() => {
    if (props.tenantConfig != null) {
      initLocalytics()
    }
  }, [props.tenantConfig])

  const routerInfo = useRouter();
  return (
    <React.Fragment>
      {props.tenantConfig == null ? (
        <Spinner
          fetchTenantConfig={props.fetchTenantConfig}
          getSiteConfig={props.getSiteConfig}
          loadPoKeys={props.loadPoKeys}
        />
      ) : (
        <>
        {/* <Localytics> WDP-8657: Commenting this as its not required*/}
            <MuiThemeProvider theme={theme}>
              <EmbeddedWrapper embededConfig={props.embededConfig}>
                <Router {...props} {...routerInfo} />
              </EmbeddedWrapper>
            </MuiThemeProvider>
          {/* </Localytics> */}
          <RoyaltyCacheTrigger />
          </>
      )}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    tenantConfig: state.tenantConfig.tenantConfig,
    tenantEntitlement: state.tenantConfig.entitlement,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    webPlayerStartTime: (appLoadStartTime) =>
      dispatch(setAppLoadStartEvent(appLoadStartTime)),
    fetchTenantConfig: () => dispatch(fetchTenantConfig()),
    getSiteConfig: () => dispatch(GetSiteConfig()),
    loadPoKeys: () => dispatch(loadPoKeys()),
    // getTenantEntitlement: () => dispatch(getTenantConfig())
  };
};

//export default App;
export default connect(mapStateToProps, mapDispatchToProps)(AppWrapper);
