import * as actionTypes from './actionTypes'

export const setselectedFiltersChannel = (filterValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_SELECTED_FILTERS_CHANNEL,
            value: filterValue
        });
    };
};

export const setKeywordsChannel = (keywordsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_KEYWORDS_CHANNEL,
            value: keywordsValue
        });
    };
};

export const setCategoriesChannel = (categoryValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_SUB_CATEGORY_CHANNEL,
            value: categoryValue
        });
    };
};

export const setDurationChannel = (durationValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_DURATION_CHANNEL,
            value: durationValue
        });
    };
};

export const setSkillLevelChannel = (skillValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SKILL_CHANNEL,
            value: skillValue
        });
    };
};

export const setFocusAreaChannel = (focusAreaValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_FOCUS_AREA_CHANNEL,
            value: focusAreaValue
        });
    };
};

export const setEquipmentsTypesChannel = (equipmentsValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_EQUIPMENTS_TYPES_CHANNEL,
            value: equipmentsValue
        });
    };
};

export const setIntensityLevelChannel = (intensityValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_INTENSITY_CHANNEL,
            value: intensityValue
        });
    };
};

export const setClassLanguageChannel = (languageValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASS_LANGUAGE_CHANNEL,
            value: languageValue
        });
    };
};

export const setSortChannel = (sortValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SORT_CHANNEL,
            value: sortValue
        });
    };
};

export const setFilterSearchTextChannel = (searchValue) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTER_SEARCH_TEXT_CHANNEL,
            value: searchValue
        });
    };
};

export const setFilteredClassSearchData = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_CLASS_SEARCH_DATA_CHANNEL,
            value: value
        });
    };
};

export const setFilteredTopKeywordsChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_FILTERED_TOP_KEYWORDS_CHANNEL,
            value: value
        });
    };
};
export const setPageCountChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGE_COUNT_CHANNEL,
            value: value
        });
    };
};

export const setPaginatedDataChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_PAGINATED_DATA_CHANNEL,
            value: value
        });
    };
};

export const setClassesSearchDataChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_CLASSES_SEARCH_DATA_CHANNEL,
            value: value
        });
    };
};

export const settempClassSearchDataChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TEMP_CLASS_SEARCH_DATA_CHANNEL,
            value: value
        });
    };
};

export const setTopKeywordsChannel = (value) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_TOP_KEYWORDS_CHANNEL,
            value: value
        });
    };
};

export const resetFiltersChannel = () => {
    return dispatch => {
        dispatch({
            type: actionTypes.RESET_FILTERS_CHANNEL,
        });
    };
};

export const setChannelMediaType = (mediaType) => {
    return dispatch => {
        dispatch({
            type: actionTypes.SET_MEDIA_TYPE_CHANNEL,
            value: mediaType
        });
    };
};