import React, { useEffect, useReducer } from "react"
import { Typography } from "@material-ui/core";
import { Skeleton } from "@material-ui/lab";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import StarColor from "../../CommonImages/color-star.js";
import StarEmpty from "../../CommonImages/star-empty.js";
import CustomDialog from "../../components/Modal/Modal";
import Routes from "../../components/Router/Routes.js";
import { useRatingReviews } from "../../hooks/useRatingReviews.js";
import { Translate } from "../../utils/Translate.js";
import StarRating from "../PlayerAndCorousel/views/RatingAndReview/UserRating.js";
import { EditIcon } from "../../CommonImages/icon-editor.js";
import config from "../../assets/config.json"
import { appendQueryParameterInURL, getPosterSize } from "utils/utilityfunctions.js";

const MyReviews = (props) => {
    const { useRatingReviewsState, useRatingReviewsDispatch, myReviewReducer } = useRatingReviews();
    const  {userClassRatingAll,reloadMyReviews,loading}  = useRatingReviewsState();
    const { fetchUserRating,resetRatingModalData,resetReloadingReviews } = useRatingReviewsDispatch();
    const {initialState,useMyReviewReducer,setReducerData} = myReviewReducer
    const [ratingReviewsState,myReviewDispatch]=useReducer(useMyReviewReducer,initialState)
    const { classId ,title ,showDailog} = ratingReviewsState
    const navigate = useNavigate();
    useEffect(()=>{
        fetchUserRating();
    },[])
    
    useEffect(()=>{
        fetchUserRating();
        resetReloadingReviews()
    },[reloadMyReviews])

    const handleEditClick = (title,classId) =>{
        setReducerData(myReviewDispatch,{classId,title,showDailog:true})
        fetchUserRating(classId)
    }
     const ratingDialogClose =() =>{
        resetRatingModalData()   
        setReducerData(myReviewDispatch,{showDailog:false})
        
    }

    const handleThumbClick = (classId) => {
        navigate(Routes.onDemandClassPlayerRoute + classId)
    }

    const getCreationDate = (utcdate) =>{
        return moment(utcdate).format("DD MMM YYYY") 
    }

    const shimmer = [...Array(5)].map((item) => { 
        return( <div>
                <div className="">
                <Skeleton role="presentation" variant="rect" width={'100%'} height={100} />
                </div>
                <div className="line rating-shimmer"></div>
            </div>)})

    return (   
        <div className="m-t-40 m-t-xs-5">
            <Typography variant="h1" className="review-page-title">
            {Translate({id: "RatingsReviews.Reviews"})}
            </Typography>
           { loading ? shimmer :
            userClassRatingAll ? userClassRatingAll.map((classRating,i)=>{
            return <div className="rating-grid-container">
                <img alt="my reviews" src={appendQueryParameterInURL(classRating?.imageLink, "width", getPosterSize("").playlistThumbnail)} className="rating-thumb makeTextClickable thumb-xs" onClick={() => handleThumbClick(classRating.tag)} role='button' aria-label='my reviews' tabIndex='0'/>
                <div className="my-rating">
                    <div className="d-flex">
                        {classRating.rating ? [...Array(classRating.rating)].map((item) => <StarColor color={config.primaryColor} cls={"my-review-star"} />) : null}
                        {(5 - classRating.rating) ? [...Array((5 - classRating.rating))].map((item) => <StarEmpty color={config.primaryColor} cls={"my-review-star"} />) : null}
                    </div>
                    <Typography Typography variant="body1">
                        {getCreationDate(classRating.creationDate)}
                    </Typography>
                </div>
                
                    <Typography variant="h2" className="reviews-title" >
                    {classRating.title}
                    </Typography>
                
                    <Typography paragraph={true} className="reviews-comment text-gray">
                    {classRating.reviewComment === "" ? Translate({id: "RatingsReviews.NoReviewComment"}):classRating.reviewComment}
                    </Typography>
                <EditIcon id={classRating.tag} onClick={() => handleEditClick(classRating.title, classRating.tag, classRating.reviewComment, classRating.rating)} color={config.primaryColor} role='button' aria-label='Reviews' tabindex='0'/>
                {showDailog ?<CustomDialog
                    open={true}
                    title={Translate({id: "RatingsReviews.RateContent"})}
                    heading={title}
                    paperReq={"ratingModal"}
                    cancelicon={true}
                    backgroundProps={"ratingModalbg"}
                    handleDialogClose={()=>ratingDialogClose()}
                >
                    <StarRating classId={classId} handleDialogClose={()=>ratingDialogClose()} />
                </CustomDialog>:null}
                {userClassRatingAll.length-1 > i ? <div className="line"></div>: null }
            </div>
           }) :
           <div className="no-classes-found">
            <Typography variant="body2" className="text-gray">{Translate({ id: "RatingsReviews.NoRatingMessage" })}</Typography>
            </div>
            }
        </div> 
    )
}
    


export default MyReviews;