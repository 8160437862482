import React, { Fragment } from 'react'
import LiveStreamCard from "./LiveStreamCard";
import { Translate } from '../../utils/Translate';
import Routes from "../../components/Router/Routes"
import CustomGrid from '../CustomGrid/CustomGrid';

import IconRigntArrow from 'CommonImages/icon-rightArrow';
import { useNavigate } from 'react-router';
import config from "../../assets/config.json"

function LiveStreamHome(props) {
   
    const {clubClasses=[]} = props;
    const navigate = useNavigate();
    //if(clubClasses.length)clubClasses.length = 3;
    const viewDetails = () => {
        navigate( Routes.connectLiveSchedule);
    };
    return (
        <Fragment>

           {clubClasses?.length? (<div className="m-t-30" >
                <div className='m-b-32 m-b-15'>
                    <div data-testid={"titleClick"} className="align-left d-flex makeTextClickable m-b-7" onClick={viewDetails} role='button' aria-label='LiveStream Home' tabIndex='0'>
                        <div className="align-left font-h1 ">{Translate({ id: "homePage.LiveScheduleTitle" })} </div>
                        <IconRigntArrow id='arrow-6' color={config.primaryColor} cls={"icon-arrow-home"} />
                    </div>
                    <div className="text-gray font-h6 align-left "  >
                        {Translate({ id: "homePage.LiveScheduleDescription" })}
                    </div>
                </div>
            </div>
          ):""}

            <div className={clubClasses?.length? "m-t-15 xm-t-xs-15 m-b-20":" m-t-xs-15 m-b-20 live-schedule-empty" }>
                <div className="live">
                    <div className="mobileScroll">
                        <CustomGrid
                            items={clubClasses} 
                            spacing={2}
                            showLoading={false}
                            isPageContainer={false}
                            classes="cardList flexNoWrap livstreamHome">
                            {(clubData)=>
                                <LiveStreamCard
                                creationDate={clubData.firstDate}
                                nextOccurence={clubData.eventOccurrenceDate}
                                activeTime={clubData.startTime}
                                eventStatus={clubData.isStreaming}
                                trailerLinkWeb={clubData.trailerLink}
                                history={props.history}
                                thumbnail={clubData?.imageUrl ? clubData.imageUrl : ""}
                                title={clubData.title}
                                duration={clubData.eventDuration
                                    ? (clubData.eventDuration)
                                    : clubData.type === "true-live" ? undefined : 55}
                                durationInSec={clubData.eventDuration 
                                    ? Math.ceil((clubData.eventDuration * 60))
                                    : clubData.type === "true-live" ? undefined : 55}
                                description={clubData.description}
                                show={true}
                                id={clubData.id}
                                // clicked={onClubCardClicked}
                                level={clubData.level}
                                isClubConnect={true}
                                action={viewDetails}
                                videoId={clubData.videoId}
                                type={clubData.eventType}
                                isUnlocked={clubData.isUnlocked}
                            />
                            }
                        </CustomGrid>
                    </div>
                </div>
            </div>

        </Fragment>
    )
}

export default LiveStreamHome

