import React from 'react'
import { Skeleton } from '@material-ui/lab'
import MoreClassesShimmer from './MoreClassesShimmer'

const PlayerShimmer = (props) => {
  return (
    <div className={props.player === 'LSPlayer' ? 'page-container' : ""}>
    <div className="vido-metadata-row">
     <Skeleton role="presentation" variant="text" width={'80%'} height={40} />
     <Skeleton role="presentation" variant="text" width={'60%'} height={20} />
     </div>
     <div className="p-t-player p-t-xs-16 info-container m-b-info-cont">
        <div>
            <div className="video-js video-wrapper">
                <Skeleton role="presentation" variant="rect" width={'100%'} height={'100%'} />
            </div>
            <div className="new-video-metadata">
                <Skeleton role="presentation" variant="text" width={'100%'} height={15} />
                <Skeleton role="presentation" variant="text" width={'100%'} height={15} />
            </div>
        </div>
       {props.player !== 'LSPlayer' && <div className="class-side-info class-side-info-shimmer ">
            <Skeleton role="presentation" variant="rect" height={200} />
        </div>}
     </div>

     {props.player !== 'LSPlayer' && <div className='progress-container'>
        <Skeleton role="presentation" variant="text" width={'100%'} height={60} />
     </div>}
     {props.player !== 'LSPlayer' && <div className='progress-container'>
        <Skeleton role="presentation" variant="text" width={'100%'} height={60} />
     </div>}
     {props.player === 'LSPlayer' &&
      <MoreClassesShimmer />
    }
    </div>
  )
}

export default PlayerShimmer