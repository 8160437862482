import React, { useEffect, useMemo } from "react";
import { Translate } from "utils/Translate";
import { Typography, Select, MenuItem, Divider } from "@material-ui/core";
import Config from "assets/config.json";
import { useSiteConfig } from "../../hooks/useSiteConfig";
import { contentListConstants } from "utils/constants";
const config = require("assets/config.json");
// 3rd Dec,2019 by Nikhil
// Expected latest Design
// Commented the code for adding these items to the footer
function Footer(props) {
  const { useSiteConfigState } = useSiteConfig()
  const { socialLinks, appBadgesLinks, customLinks,entitlement } = useSiteConfigState()
  const {
    language,
    copyright,
    supportedLanguages = [],
    langauageSelector = false,
  } = Config;
  const version = localStorage.getItem("version");
  const [open, setOpen] = React.useState(false);
  useEffect(() => {
    if (langauageSelector) {
      const selectedLanguage = localStorage.getItem("language");
      if (!supportedLanguages.includes(selectedLanguage)) {
        localStorage.setItem("language", language);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const languageValues = supportedLanguages?.map((items) => {
    const id = "language." + items;
    return Translate({ id, defaultMessage: items, values: items });
  });
  const handleChange = (event) => {
    localStorage.setItem("language", event.target.value);
    window.location.reload();
  };

  const copyrightValue = () => {
    const currentYear = new Date().getFullYear();
    return copyright ?? `© Wexer ${currentYear}`; //fallback in case api doesn't pass value
  };

  // TODO try using use memo an rename 
  const contentListItems = () => {
    const listItems = Object.keys(entitlement)
    const contentList = []
    listItems.forEach(item => { 
      if (entitlement[item]) {
        contentList.push(item)
      }
     });
     return contentList
  }

  const contentList = contentListConstants;
  return (
    <footer role="contentinfo">
      <div className="Footer">
        <div className="d-flex align-item-start ">

          <div className="d-flex container-links ">
            {contentListItems().length > 0 ? <div className="container-1">
              <div className="footer-links-title">
             {Translate({ id: "footer.Content" })}
              </div>
              {contentListItems().map((item)=>{
                  return   <div className="footer-links-title">
                   {contentList[item] && <a href={contentList[item].route} alt={contentList[item].title} aria-label={contentList[item].title} className="footer-h3" data-testid={contentList[item].id}>
                  {Translate({ id: contentList[item].title })}
                    </a>}
                    </div>      
              }) }
            </div> : null}
            <div className="container-2">
            <div className="footer-links-title">
              {Translate({ id: "footer.Support" })}
              </div>
              <div className="footer-links-title">
                <a href="/contact" alt="ContactUs" aria-label="ContactUs" className="footer-h3">
             {Translate({ id: "footer.contactUs" })}
                </a>
                </div>
                <div className="footer-links-title">
                <a href="/tc" className="footer-h3" alt="TermsAndConditions" aria-label="TermsAndConditions">
               {Translate({ id: "footer.TermsAndConditions" })}
                </a>
                </div>
            </div>
                   
            {customLinks && customLinks?.length !== 0 ? <div className="container-1">
            <div className="footer-links-title">
             {Translate({ id: "Player.More" })}
             </div>
                {customLinks?.map((customLink) => {
                  if (customLink.status) {
                    const linkHoverText = customLink.linkHoverText?.localTexts.find(obj => obj["ietfTag"] === language)?.text ?? customLink.linkHoverText?.invariantText
                    const linktext = customLink.linkText?.localTexts.find(obj => obj["ietfTag"] === language)?.text ?? customLink.linkText?.invariantText;
                    return  <div className="footer-links-title">
                    <a target="_blank" href={customLink?.httpLink} aria-label={customLink?.httpLink} title={linkHoverText} className="footer-h3" alt="linktext">
                    <span class="sr-only">new window</span>{linktext}
                    </a>
                    </div>
                  }
                }
                )}
            </div> : ""}
          </div>

          <div className="container-links-social">
            {socialLinks?.map((socialLink,index) => {
              if (socialLink.status) {
                return <a target="_blank" href={socialLink?.httpLink} aria-label={socialLink?.httpLink} alt="SocialLink" >
                  <span class="sr-only">new window</span><img src={socialLink.imageURL} alt={ `SocialLink ${index}`}/>
                </a>
              }
            }
            )}
          </div>

          <div className="container-languageselector-appbadges">
            <div className="languageselector-appbadges">
              {langauageSelector && supportedLanguages.length && (
                <>
                  <div  className="footer-links-title language-switcher">
                    {Translate({
                      id: "labelforlanguageswitcher.SiteLanguageLabel",
                    })}
                    <br />
                    <Select
                      labelId="langauage-selector-label"
                      id="langauage-selector"
                      open={open}
                      onClose={() => setOpen(false)}
                      onOpen={() => setOpen(true)}
                      value={localStorage.getItem("language")}
                      onChange={handleChange}
                    >
                      {supportedLanguages?.map((suportedLanguage, index) => (
                        <MenuItem
                          className="langauage-switcher"
                          value={suportedLanguage}
                        >
                          {languageValues[index] ?? suportedLanguage}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </>
              )}
            </div>
            <div className="languageselector-appbadges container-appbadges">
              {appBadgesLinks?.map((appBadgesLink,index) => {
                return appBadgesLink.status ?
                  <a target="_blank" href={appBadgesLink?.httpLink} aria-label={appBadgesLink.imageURL} alt="AppBadgesLink">
                    <span class="sr-only">new window</span><img src={appBadgesLink.imageURL} alt={ `AppBadgesLink ${index}`}/>
                  </a> : null
              }
              )}
            </div>
          </div>

        </div>
      </div>

      <div className="d-flex justify-content-spacebtw footer-lower">
        <p className="textLeft-xs">
          {copyrightValue()}&nbsp;{Translate({ id: "footer.Copyright" })}
        </p>
        <p className="textLeft-xs text-right">
          {Translate({ id: "footer.Build" })}
          {": "}
          {version}
        </p>
      </div>
    </footer>
  );
}

export default Footer;
