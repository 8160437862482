import React, { Fragment, useEffect } from "react";
import PageTitle from "../../components/PageTitle";
import { Translate } from "../../utils/Translate";
import { Typography, Button } from "@material-ui/core";
import Routes from '../../components/Router/Routes'
import { usePaymentDone } from "../../hooks/usePaymentDone";
import { useNavigate } from "react-router";

function PaymentDone(props) {
  const navigate = useNavigate();
  const goBackToHome = () => {
    navigate(Routes.homePage)
  }
  const isAuthenticated = localStorage.getItem("token");

  const { usePaymentDoneState, usePaymentDoneDispatch } = usePaymentDone();

  const { navigatedFromWhichScreen} = usePaymentDoneState() ;

  const {setNavigatedFromWhichScreen} = usePaymentDoneDispatch()

  useEffect(()=>{
    if (isAuthenticated) {
      if (navigatedFromWhichScreen === "SUCCESS") {
        navigate('/user/account')
        setNavigatedFromWhichScreen("")
    }}
    else{
      setNavigatedFromWhichScreen("SUCCESS")
      navigate("/signin")
    }
  },[])
  return (
    <Fragment>
      <div className="page-container m-t-xs-20">
        <PageTitle className="m-t-payment" label= {Translate({ id: "Subscription.FinalHead" })}/>
        <Typography variant="h6" className="text-gray subscriptionTagline">
        {Translate({ id: "Subscription.FinalMsg" })}
                    </Typography>
        <Button fullWidth
          variant="contained"
          color="secondary"
          className="m-payment-Done-btn m-t-xs-20 payButton btn-default"
          onClick={goBackToHome}
          role='button'
          aria-label='Back to home page'
          tabindex='0'
        >
          <Typography variant="button" role='button' aria-label='Back to home page' tabindex='0'>
            {Translate({ id: "Subscription.backbutton" })}
            
          </Typography>
        </Button>
      </div>
    </Fragment>
  )
};

export default PaymentDone;
