import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    selectedFilters: [],
    filteredSkill: [],
    filteredDuration: '',
    filteredKeywords: [],
    filteredFocusArea: [],
    filteredEquipments: [],
    filteredIntensity: [],
    filteredLanguage: '',
    filteredSubCategory: '',
    filterSortBy: '',
    filterSearchText: '',
    filteredClassSearchData: [],
    filteredTopKeywords: [],
    loading: false,
    pageCount: 0,
    paginatedData: [],
    classesSearchDataChannel: [],
    tempClassSearchDataChannel: [],
    topKeywordsChannel: [],
    filteredMediaType: [],
};

const setSelectedFilters = (state, action) => {
    return updateObject(state, {
        selectedFilters: action.value,
    });
};

const setFilterSkill = (state, action) => {
    return updateObject(state, {
        filteredSkill: action.value,
    });
};

const setFilterDuration = (state, action) => {
    return updateObject(state, {
        filteredDuration: action.value,
    });
};

const setFilterkeywords = (state, action) => {
    return updateObject(state, {
        filteredKeywords: action.value,
    });
};

const setFilterFocusArea = (state, action) => {
    return updateObject(state, {
        filteredFocusArea: action.value,
    });
};

const setFilterEquipments = (state, action) => {
    return updateObject(state, {
        filteredEquipments: action.value,
    });
};

const setFilterIntensity = (state, action) => {
    return updateObject(state, {
        filteredIntensity: action.value,
    });
};

const setClassLanguage = (state, action) => {
    return updateObject(state, {
        filteredLanguage: action.value,
    });
};

const setFilteredSubCategory = (state, action) => {
    return updateObject(state, {
        filteredSubCategory: action.value,
    });
};

const setFilterSort = (state, action) => {
    return updateObject(state, {
        filterSortBy: action.value,
    });
};

const setFilterSearchText = (state, action) => {
    return updateObject(state, {
        filterSearchText: action.value,
    });
};

const setFilteredClassSearchData = (state, action) => {
    return updateObject(state, {
        filteredClassSearchData: action.value,
    });
};

const setFilteredTopKeywords = (state, action) => {
    return updateObject(state, {
        filteredTopKeywords: action.value,
    });
};

const setPageCounts = (state, action) => {
    return updateObject(state, {
        pageCount: action.value,
    });
};
const setPaginatedDatas = (state, action) => {
    return updateObject(state, {
        paginatedData: action.value,
    });
};
const setTopKeywChannel = (state, action) => {
    return updateObject(state, {
        topKeywordsChannel: action.value,
    });
};

const setMediaTypeChannel = (state, action) => {
    return updateObject(state, {
        filteredMediaType: action.value,
    });
};

const resetFilter = (state, action) => {
    return updateObject(state, {
        selectedFilters: [],
        filteredSkill: [],
        filteredDuration: "",
        filteredKeywords: [],
        filteredFocusArea: [],
        filteredEquipments: [],
        filteredIntensity: [],
        filteredLanguage: "",
        filteredSubCategory: "",
        filterSortBy: "",
        filterSearchText: "",
        filteredClassSearchData: [],
        filteredTopKeywords: [],
        loading: false,
        pageCount: 0,
        paginatedData: [],
        tempClassSearchDataChannel: [],
        filteredMediaType: []
    });
};

const setClassesDataChannel = (state, action) => {
    return updateObject(state, {
        classesSearchDataChannel: action.value,
    });
};
const setTempClassesSearchdataChannel = (state, action) => {
    return updateObject(state, {
        tempClassSearchDataChannel: action.value,
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SET_SELECTED_FILTERS_CHANNEL:
            return setSelectedFilters(state, action);
        case actionTypes.SET_FILTER_KEYWORDS_CHANNEL:
            return setFilterkeywords(state, action);
        case actionTypes.SET_FILTER_DURATION_CHANNEL:
            return setFilterDuration(state, action);
        case actionTypes.SET_FILTER_SKILL_CHANNEL:
            return setFilterSkill(state, action);
        case actionTypes.SET_FILTER_FOCUS_AREA_CHANNEL:
            return setFilterFocusArea(state, action);
        case actionTypes.SET_FILTER_EQUIPMENTS_TYPES_CHANNEL:
            return setFilterEquipments(state, action);
        case actionTypes.SET_FILTER_INTENSITY_CHANNEL:
            return setFilterIntensity(state, action);
        case actionTypes.SET_CLASS_LANGUAGE_CHANNEL:
            return setClassLanguage(state, action);
        case actionTypes.SET_FILTER_SORT_CHANNEL:
            return setFilterSort(state, action);
        case actionTypes.SET_FILTER_SEARCH_TEXT_CHANNEL:
            return setFilterSearchText(state, action);
        case actionTypes.SET_FILTERED_SUB_CATEGORY_CHANNEL:
            return setFilteredSubCategory(state, action);
        case actionTypes.SET_FILTERED_CLASS_SEARCH_DATA_CHANNEL:
            return setFilteredClassSearchData(state, action);
        case actionTypes.SET_FILTERED_TOP_KEYWORDS_CHANNEL:
            return setFilteredTopKeywords(state, action);
        case actionTypes.SET_PAGE_COUNT_CHANNEL:
            return setPageCounts(state, action);
        case actionTypes.SET_PAGINATED_DATA_CHANNEL:
            return setPaginatedDatas(state, action);
        case actionTypes.SET_TOP_KEYWORDS_CHANNEL:
            return setTopKeywChannel(state, action);
        case actionTypes.SET_CLASSES_SEARCH_DATA_CHANNEL:
            return setClassesDataChannel(state, action);
        case actionTypes.SET_TEMP_CLASS_SEARCH_DATA_CHANNEL:
            return setTempClassesSearchdataChannel(state, action);
        case actionTypes.RESET_FILTERS_CHANNEL:
            return resetFilter(state, action);
        case actionTypes.SET_MEDIA_TYPE_CHANNEL:
            return setMediaTypeChannel(state, action);
        default:
            return state;
    }
};
export default reducer;
