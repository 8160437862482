import React from "react"

function CloseGreen(props){
    const color = props.color
    return (<div className={props.cls} onClick={props.onClick} role='button' aria-label='close' tabIndex='0'>
        <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                    <path d="M36.6 11.42a1.992 1.992 0 0 0-2.82 0L24 21.18l-9.78-9.78a1.992 1.992 0 0 0-2.82 0c-.78.78-.78 2.04 0 2.82L21.18 24l-9.78 9.78c-.78.78-.78 2.04 0 2.82.78.78 2.04.78 2.82 0L24 26.82l9.78 9.78c.78.78 2.04.78 2.82 0 .78-.78.78-2.04 0-2.82L26.82 24l9.78-9.78c.76-.76.76-2.04 0-2.8z" id="sgzovd94wa" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <use xlinkHref="#sgzovd94wa" fill={color} />
                </g>
            </svg>
        </div>)
}

export default CloseGreen
