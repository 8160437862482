const config = require("../../assets/config.json");
import { isEmbeddedMode } from "../utilityfunctions";

export function logDetailsInLocalytics(tagName, tagDetails) {
  LocalyticsLogger.log("logDetailsInLocalytics ", tagName, tagDetails);
  window.ll("tagEvent", tagName, tagDetails);
}

export function initLocalytics() {
  let localyticsInitSet;
  if (
    localStorage.getItem("userId") !== null &&
    localStorage.getItem("token") !== null
  ) {
    //If user is logged in se customer id in init method else only CDs
    localyticsInitSet = {
      customDimensions: [localStorage.getItem("clientId")],
      customerId: localStorage.getItem("userId"),
    };
  } else {
    const baseCustomDimensions = [
      localStorage.getItem("clientId"),
      "0",
      "UNKNOWN",
      "UNKNOWN",
      new Date("0001").toDateString(),
      new Date("0001").toDateString(),
      "UNKNOWN",
      0,
      "UNKNOWN",
      "UNKNOWN",
      isEmbeddedMode() ? "1" : "0"
    ];
    localyticsInitSet = {
      customDimensions: baseCustomDimensions,
      customerId: "",
    };    
    localStorage.setItem("_loc_ids", null);
  }

  LocalyticsLogger.log("YKS: localyticsInitSet ", localyticsInitSet);
  //--------------------------------------------------Localytics INIt--------------------
  (function (l, y, t, i, c, s) {
    l["LocalyticsGlobal"] = i;
    l[i] = function () {
      (l[i].q = l[i].q || []).push(arguments);
    };
    l[i].t = +new Date();
    (s = y.createElement(t)).type = "text/javascript";
    s.src =
      config.localytics ?? "https://web.localytics.com/v3/localytics.min.js";
    (c = y.getElementsByTagName(t)[0]).parentNode.insertBefore(s, c);
    window.ll("init", process.env.REACT_APP_LOCALYTICS_APP_ID, {
      ...localyticsInitSet,
      sessionTimeout: parseInt(process.env.REACT_APP_LOCALYTICS_TIMEOUT),
    });
  })(window, document, "script", "ll");
}

export function setProfileAttribute(param) {
  const { name, value } = param;
  LocalyticsLogger.log("setProfileAttribute ", name, value);
  if (name) {
    window.ll("setProfileAttribute", name, value, "app");
  }
}

export function setCustomDimension(dimension, value) {
  LocalyticsLogger.log("setCustomDimension ", dimension, value);
  window.ll("setCustomDimension", dimension, value);
}

export function setCustomerId(customerId) {
  LocalyticsLogger.log("setCustomerId ", customerId);
  window.ll("setCustomerId", customerId);
}

export function setCustomerEmail(customerEmail) {
  LocalyticsLogger.log("setCustomerEmail ", customerEmail);
  window.ll("setCustomerEmail", customerEmail);
}


/// Localytics Logger
export const LocalyticsLogger = {
  log: (...args) => {
    console.log(...args);
  }  
};