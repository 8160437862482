import { useDispatch, useSelector } from "react-redux";
import { clearAllChannelClasses, fetchAllChannels } from "../store/actions/channels";
import { SAVE_DEEPLINK_PAYLOAD } from "../store/actions/actionTypes";
import { fetchChannelClasses, searchOnDemandClass } from "store/actions/ondemand";
import { setFilteredClassSearchData } from "store/actions/filter";
import { resetFiltersChannel, setPaginatedDataChannel, setselectedFiltersChannel, settempClassSearchDataChannel, setPageCountChannel, setKeywordsChannel, setTopKeywordsChannel, setFilteredTopKeywordsChannel } from "store/actions/filterChannel";
import { maxInteger } from "utils/utilityfunctions";
const useChannelView = () => {
  const dispatch = useDispatch();
  const useChannelViewState = () => ({
    channelsTiltes: useSelector((state) => state.channels.classes),
    isClassesLoading: useSelector((state) => state.channels.isClassesLoading),
    isCalled: useSelector((state) => !state.channels.isClassesLoading && !state.channels.classLoadingFailed),
    channels: useSelector(state => state.channels.allChannels ),
    tempFilterSearchClasses: useSelector(state => state.channels?.tempFilterSearchClasses),
    selectedFilters: useSelector(state => state.filterChannel?.selectedFilters),
    loading: useSelector(state => state.Channel?.liveConnectLoading),
    filteredClassSearchData: useSelector(state => state.filterChannel?.filteredClassSearchData),
    filteredSkill: useSelector(state => state.filterChannel?.filteredSkill),
    filteredDuration: useSelector(state => state.filterChannel?.filteredDuration),
    filteredKeywords: useSelector(state => state.filterChannel?.filteredKeywords),
    filteredSubCategory: useSelector(state => state.filterChannel?.filteredSubCategory),
    filteredFocusArea: useSelector(state => state.filterChannel?.filteredFocusArea),
    filteredEquipments: useSelector(state => state.filterChannel?.filteredEquipments),
    filterSortBy: useSelector(state => state.filterChannel?.filterSortBy),
    filterSearchText: useSelector(state => state.filterChannel?.filterSearchText),
    filteredIntensity: useSelector(state => state.filterChannel?.filteredIntensity),
    filteredLanguage: useSelector(state => state.filterChannel?.filteredLanguage),
    filteredTopKeywords: useSelector(state => state.filterChannel?.filteredTopKeywords),
    filteredMediaType: useSelector(state => state.filterChannel?.filteredMediaType),
    pageCount: useSelector(state => state.filterChannel?.pageCount),
    paginatedData: useSelector(state => state.filterChannel?.paginatedData),
    classesSearchDataChannel: useSelector(state => state.filterChannel?.classesSearchDataChannel),
    tempClassSearchDataChannel: useSelector(state => state.filterChannel?.tempClassSearchDataChannel),
    topKeywordsChannel: useSelector(state => state.filterChannel?.topKeywordsChannel),
    // searchedChannelClassesObj: useSelector(state => state.channel?.Channel),
    resetInProgress: useSelector(state => state.channel?.resetInProgress),
    searchOnDemandMetadata: useSelector((state) => state.channels.searchOnDemandMetadata)
  });

  const useChannelViewDispatch = () => ({
    clearAllChannelClasses: () =>{
      dispatch(clearAllChannelClasses())
    },
    saveDeeplinkPayload: (path) => {
      dispatch({ type: SAVE_DEEPLINK_PAYLOAD, deeplinkData: path });
    },
    fetchAllChannels: () => dispatch(fetchAllChannels()),
    fetchChannelClasses: (data) => {
      dispatch(fetchChannelClasses(data));
    },
    searchOnDemandClassWithFilter: (channelTag,filteredKeywords, filteredFocusArea, filteredSkill, filteredDuration, filteredEquipments, filteredIntensity, searchText, filterSortBy, filteredLanguage, filteredSubCategory, fromSearch, providerId, filteredMediaType) => {
      dispatch(searchOnDemandClass( "channels", channelTag, filteredKeywords, false, 1, 0, maxInteger, false, filteredFocusArea.length > 0 ? filteredFocusArea[0].filterValue : "", filteredSkill, filteredEquipments.length > 0 ? filteredEquipments[0].filterValue : "", filteredDuration, filteredIntensity, searchText, filterSortBy, filteredLanguage.length > 0 ? filteredLanguage[0].filterValue : "", filteredSubCategory, fromSearch, "", filteredMediaType.length > 0 ? filteredMediaType : ""));
    },
    setChannelTopKeywords: (value) => {
      dispatch(setTopKeywordsChannel(value))
    },
    setTempClassSearchChannelData: (tempItems) => {
      dispatch(settempClassSearchDataChannel(tempItems))
    },
    setFilteredTopKeywords: (sortedKeywords) => {
      dispatch(setFilteredTopKeywordsChannel(sortedKeywords))
    },
    setPageCount: (page) => {
      dispatch(setPageCountChannel(page))
    },
    setChannelPaginatedData: (data) => {
      dispatch(setPaginatedDataChannel(data))
    },
    setChannelKeywords: (filteredValue) => {
      dispatch(setKeywordsChannel(filteredValue));
    },
    setSelectedFiltersChannel: (tempFilters) => {
      dispatch(setselectedFiltersChannel(tempFilters))
    },
    resetChannelFilters: () => {
      dispatch(resetFiltersChannel());
    },
    setFilteredClassSearchData: (tempData) => {
      dispatch(setFilteredClassSearchData(tempData))
    },
  });
  const initialState = {
    channelsData: null,
    titlesToDispay: [],
    hasMoreData: false,
    count:0,
    channels: [],
    hasMoreData: false,  
    classesCount: "",
    showNoDataFound: false,
    loadMoreAllCalled: false,
    pageCount:0
  };
  const useChannelActions = {
    UPDATE_CHANNEL: "UPDATE_CHANNEL",
  };
  const useChannelReducer = (state, action) => {
    const {type,payload} = action;
    switch (type) {
      case useChannelActions.UPDATE_CHANNEL:
        return {...state,...payload};
      default:
        return state
    }
  };
  const setReducerData = (channelDispatch,payload)=>{
    channelDispatch({
        type:useChannelActions.UPDATE_CHANNEL,
        payload:payload
    });
  }
    
  return {
    useChannelViewState,
    useChannelViewDispatch,
    channelReducer: { useChannelReducer, useChannelActions, initialState,setReducerData },
  };
};
export { useChannelView };