import React, { useState,useEffect } from 'react'
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import Close from '@material-ui/icons/Close';
import PropTypes from 'prop-types'
import { Translate } from '../../utils/Translate';
import { getTranslatedFile } from '../../utils/utilityfunctions';
import { MINIMUM_GLOBAL_SEARCH } from '../../utils/constants';
import { useSelector } from 'react-redux';
import config from "../../assets/config.json";
const SearchItems = props => {
    const showSearch = useSelector(state=> state.globalSearch?.showSearch)
    const {closeSearch,handelBack,searchText,loading,noCrossIcon,focus,textBox,showError,crossIconId} = props;
    const translatedFile = getTranslatedFile()
    const [isError, setError] = useState(showError)
    const isValid = ()=>{
       const searchEle = document.querySelector(textBox);
       return searchEle?.value?.length > MINIMUM_GLOBAL_SEARCH;
    }
    const handelSearch = (e)=> {if(e.key === "Enter")  handelSubmit() };
    const handleKeyDown = (e) => e.stopPropagation()
    const handelSubmit = ()=>{
        if(isValid()){
            isError && setError(false);
            handelBack();
        }
        else{
            !isError && setError(true);
        }
    }
    useEffect(()=>{
         if(showSearch){
            const inputEle = document.querySelector("#search-box");
            const cross = document.querySelector("#crossIconId");
            inputEle.addEventListener('click', event => {
                event.stopPropagation()
            })
            cross.addEventListener('click', event => {
                closeSearch()
            })
         }
    },[showSearch])

  return (
    <div className='search-items'>
        <div className={`comp-search ${loading ? 'disable' : ''}`}>
            <div className='icon-search' role='button'  tabindex='0'  aria-label="search"><SearchIcon onClick={handelSubmit} height={24} width={24} /></div>
            <div className='text-box'><InputBase autoFocus={focus} readOnly={loading} defaultValue={searchText} onKeyUp={handelSearch} onKeyDown={handleKeyDown}
              placeholder={translatedFile?.ClassListViews?.SearchClasses
                ? translatedFile?.ClassListViews?.SearchClasses
                : "Search Classes"}
             /></div>
            {!noCrossIcon && <div className='icon-close' id={crossIconId} role='button'  tabindex='0'  aria-label="close"><Close onClick={closeSearch} height={24} width={24} color={config.primaryColor}/></div>}
        </div>
        {isError && <div className='error-msg'>{Translate({ id: "ClassListViews.SearchMinCharMessage"})}</div>}
    </div>
  )
}
SearchItems.defaultProps = {
    closeSearch : ()=>{},
    handelBack : ()=>{},
    searchText : '',
    loading : false,
    noCrossIcon : false,
    focus : false,
    showError : false,
    textBox : ".text-box input",
}

SearchItems.propTypes = {
    closeSearch : PropTypes.func,
    handelBack : PropTypes.func,
    searchText : PropTypes.string,
    textBox : PropTypes.string,
    loading : PropTypes.bool,
    noCrossIcon : PropTypes.bool,
    showError : PropTypes.bool,
    focus : PropTypes.bool,
}

export { SearchItems}