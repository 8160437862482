import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../../utils/updateObject";

const initialState = {
    moreClassesData: [],
    loading: false,
    moreCollectionListClasses: {}
};

const setMoreCollectionClasses = (state, action) => {
    return updateObject(state, {
        moreCollectionListClasses: action.collectionTitleListClasses[0],
        loading: false,
    });
};
const setMoreClasses = (state, action) => {
    return updateObject(state, {
        moreClassesData: action.value,
        loading: false
    });
};

const resetMoreClasses = (state, action) => {
    return updateObject(state, {
        moreClassesData: [],
        moreCollectionListClasses: [],
        loading: false
    });
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_MORE_CLASSES_START:
            return updateObject(state, {
                moreClassesData: [],
                loading: true
            });
        case actionTypes.FETCH_MORE_COLLECTION_CLASSES_START:
            return updateObject(state, {
                moreCollectionListClasses: {},
                loading: true
            });
        case actionTypes.GET_MORE_CLASSES:
            return setMoreClasses(state, action)
        case actionTypes.FETCH_MORE_COLLECTION_CLASSES_SUCCESS:
            return setMoreCollectionClasses(state, action);
        case actionTypes.FETCH_MORE_COLLECTION_CLASSES_FAIL:
            return setMoreCollectionClasses(state, action);
        case actionTypes.RESET_MORE_CLASSES:
            return resetMoreClasses(state, action);
        default:
            return state;
    }
};
export default reducer;
