import React from "react";
import PropTypes from "prop-types";
import { Skeleton } from "@material-ui/lab";

const CarouselShimmer = (props) => {
  const items = [...Array(1)];
  return (
    <div>
      {items.map((_, index) => (
        <div className="carousel-page-shimmer" key={index}>
          <div className="carousel-page-shimmer-item">
            <Skeleton
              variant="rect"
              className="carousel-page-shimmer"
              width={"100%"}
              height={100}
            />
          </div>
        </div>
      ))}
    </div>
  );
};

CarouselShimmer.propTypes = {};

export { CarouselShimmer };
