import React, { useRef } from 'react';
import { Grid, Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import { useNavigate } from 'react-router';

const imageURL = require('../../assets/images/logo.png');
const imageURL3x = require('../../assets/images/logo@3x.png');
const imageURL2x = require('../../assets/images/logo@2x.png');

const config = require("../../assets/config.json");


const Logo = (props) => {
    const navigate = useNavigate();
    const logoRef = useRef();

    const handleLogoClass = ()=>{
        const logoWidth = logoRef.current.naturalWidth
        const logoHolder = document.getElementsByClassName('logoHolder')[0]
        const miniLogo = ["basefit","beoffices","arkliving"]; // using this because the content of logo is small but width is fine
        if(!miniLogo.includes(config.name)){
            (logoWidth > 300) &&  logoHolder.classList.add('flex-auto')
        }
    }
    const showLogo = () => {
        return (config.embeddedConfig?.isEmbedded ? config.embeddedConfig?.showLogo : true)
    }

    return (
    <div  onClick={() => {

        if(!(config.embeddedConfig &&config.embeddedConfig["content-mode"] &&config.embeddedConfig["content-mode"].toLowerCase() === "tenant")){
            navigate( "/" );
        } else{
            navigate("/connect/clublist");
        }
    }}
        className="logoHolder" role='button'  aria-label='Logo' tabindex='0'>
        {
            (!config.embeddedConfig || showLogo())?
            <React.Fragment>
                <div style={{
                }} >
                    <picture>
                        <source media="(min-width: 1050px)" srcSet={imageURL3x} width='100%' alt="Logo" />
                        <source media="(min-width: 465px)" srcSet={imageURL2x} width='100%' alt="Logo" />
                        <img tabindex="0" ref={logoRef} onLoad={handleLogoClass} className="logoImg" src={imageURL}  alt="Logo" role='button'  aria-label='Logo' tabIndex='0'/>
                    </picture>
                </div>
            </React.Fragment>:<React.Fragment>
            <Grid
              item
              className="makeTextClickable"
            >
              <Typography className="logo-name align-left" style={{color:config.primaryColor}}>
                {Translate({ id: "footer.home" })}
              </Typography>
            </Grid>
            </React.Fragment>
        }


    </div>
);}

export default Logo;