import React, { useEffect, useState } from "react";
import Slider from "hoc/SliderContainer";
import { Typography, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Translate } from "../../../../utils/Translate";
import LoadingClassesShimmer from "../../../../components/Shimmer/LoadingClassesShimmer";
import { useBrowseCollections } from "../../../../hooks/useBrowseCollections";
import { ICON_BLACK_COLOR, LAYOUT_TYPE, MaxItemCount } from "utils/utilityfunctions";
import { useNavigate } from "react-router";
import "./BrowseCollections.scss";
import BannerComponent from "components/Banner/BannerComponent";
import CarouselSliderComponent from "components/carousel/CarouselSliderComponent";
import Routes from "components/Router/Routes";
import { getTranslatedFile } from "../../../../utils/utilityfunctions";
import IconList from "CommonImages/list-icon";
import IconGrid from "CommonImages/grid-icon";
import config from "assets/config.json";
import CustomGrid from "containers/CustomGrid/CustomGrid";
import Workout from "components/PlayerAndCarousel/Workout/Workout";
import { PlaylistShimmer } from "containers/User/PlayLists/Playlist.shimmer";
import { CarouselShimmer } from "components/carousel/CarouselShimmer";

const BrowseCollections = (props) => {
  const [layout, setLayout] = useState("grid");
  const navigate = useNavigate();
  const { useBrowseCollectionsDispatch } = useBrowseCollections();
  const language = getTranslatedFile();
  const { collectionListClasses, fetchOnDemandClasses } =
    useBrowseCollectionsDispatch();
  const clientId = localStorage.getItem("clientId");
  const collectionArray = useSelector(
    (state) => state.onDemand.featuredAndManagedCollectionList
  );
  const loading = useSelector(
    (state) => state.onDemand.loading
  );

  const handleClick = (layout) => () => {
    setLayout(layout);
  };

  useEffect(() => {
    const data = {
      tenantId: clientId,
      pageNo: 0,
      pageSize: 0,
      collectionType: "",
      ItemCount: MaxItemCount,
    };
    collectionListClasses(data);
  }, []);

  const onClickHandler = (id) => {
    navigate(`${Routes.collections}/${id}`);
  };

  const extractedData = collectionArray?.slice(0, MaxItemCount)?.map((collection) => ({
    name: collection?.collectionName,
    description: collection?.description,
    image: collection?.collectionItems?.[0]?.imageLink,
    tag: collection?.collectionTag,
    visit: Translate({ id: "ClassListViews.VisitCollection" })
  }));

  

  return (
    <div className="main-margin">
      <div className="fixedWidthContainer container">
        <div>
          {collectionArray && !loading && collectionArray.length > 0 ? (
            <CarouselSliderComponent props={extractedData}  handleClick={onClickHandler}  />
          ) : (
            <CarouselShimmer />
          )}
        </div>
      </div>
      <div className="container">
        {collectionArray && !loading && collectionArray.length > 0 && (
          <div className="list-grid-view-area">
            <div className="grid-list-view">{Translate({ id: "ClassListViews.View" })}</div>
            <div className="line-grid-view"></div>
            <div onClick={handleClick(LAYOUT_TYPE.grid)} className="list-icon">
              <IconList
                color={
                  layout == LAYOUT_TYPE.grid ? config.primaryColor : ICON_BLACK_COLOR.iconBlackColor
                }
              />
            </div>
            <div onClick={handleClick(LAYOUT_TYPE.list)}>
              <IconGrid
                color={
                  layout == LAYOUT_TYPE.list ? config.primaryColor : ICON_BLACK_COLOR.iconBlackColor
                }
              />
            </div>
          </div>
        )}
      </div>
      <div
        className={`collection-list collectionPage main-page-container list-container-gap collection-list-page collection-margin-top ${
          layout == LAYOUT_TYPE.grid
            ? "browser-collection-grid-page"
            : "browser-collection-list-page"
        }`}
      >
        {collectionArray && !loading ? (
          collectionArray?.length > 0 ? (
            layout == LAYOUT_TYPE.list ? (
              collectionArray.map((collection) => (
                <Slider
                  key={collection?.collectionTag}
                  collectionTag={collection?.collectionTag}
                  collectionName={collection?.collectionName}
                  items={collection?.collectionItems}
                  description={collection?.description}
                  listClass={"list"}
                  navigate={navigate}
                />
              ))
            ) : (
              <CustomGrid
                items={collectionArray}
                totalItems={collectionArray?.length}
                showLoading={false}
                classes="cardList flexNoWrap"
                listClass={"list list-live-page"}
                itemClass="cardAlign"
                containerClass=""
                isPageContainer={false}
              >
                {(item, index) => (
                  <Workout
                    collectionType="featured"
                    cardType="channel-page-card"
                    isSliderWorkout={true}
                    thumbnail={`${item?.collectionItems[0]?.imageLink}`}
                    styledClass="CollectionPageCardButton "
                    clicked={() => onClickHandler(item?.collectionTag)}
                    title={item?.collectionName}
                    isUnlocked={true}
                    skipIsUnlockedCheck={true}
                    show={false}
                    section={"collection"}
                    totalClasses={item?.totalClassCount}
                    titleClickable={true}
                  />
                )}
              </CustomGrid>
            )
          ) : (
            <LoadingClassesShimmer />
          )
        ) : (
          <LoadingClassesShimmer />
        )}
      </div>
    </div>
  );
};

export default BrowseCollections;
