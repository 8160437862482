import React from "react"

function CheckIcon(props) {

    const color = props.color

    return <div className="icon-check">
        <svg viewBox="0 0 92 91" xmlns="http://www.w3.org/2000/svg">
            <g fill={color} fill-rule="nonzero">
                <path d="M45.482 91C70.39 91 91 70.39 91 45.517 91 20.61 70.355 0 45.448 0 20.575 0 0 20.61 0 45.517 0 70.39 20.61 91 45.482 91zm0-6.498c-21.588 0-38.95-17.396-38.95-38.985 0-21.623 17.327-38.985 38.916-38.985 21.623 0 39.02 17.362 39.02 38.985 0 21.589-17.362 38.985-38.986 38.985z" />
                <path d="M40.726 68c1.285 0 2.326-.6 3.09-1.798l21.281-33.879c.452-.705.903-1.586.903-2.432C66 28.163 64.472 27 62.91 27c-1.007 0-1.979.635-2.673 1.763l-19.65 31.693-9.79-12.445c-.902-1.199-1.77-1.55-2.811-1.55-1.667 0-2.986 1.374-2.986 3.101 0 .846.347 1.692.903 2.433l11.595 14.207C38.47 67.472 39.442 68 40.726 68z" />
            </g>
        </svg>
    </div>

}

export default CheckIcon
