/// All Localytics Event Name
export const LocalyticsEventName = {
  USER_LOGIN: "User Login",
  ON_DEMAND_CLASS_VIEWED: "On Demand Class Viewed",
  ON_DEMAND_CLASS_STARTED: "On Demand Class Started",
  ON_DEMAND_CLASS_PERFORMED: "On Demand Class Performed",
  LIVE_CONNECT_CLASS_VIEWED: "Live Connect Class Viewed",
  LIVE_CONNECT_CLASS_STARTED: "Live Connect Class Started",
  LIVE_CONNECT_CLASS_PERFORMED: "Live Connect Class Performed",
  CHANNEL_VIEWED: "Channel Viewed",
  CATEGORY_VIEWED: "Category Viewed",
};

/// Localytics Attributes Name
export const LocalyticsAttributesName = {
  CLIENT_ID: "Client ID",
  USER_ID: "User ID",

  CLASS_ID: "Class ID",
  CLASS_NAME: "Class Name",
  CLASS_DURATION: "Class Duration",
  CLASS_DURATION_SECONDS: "Class Duration Seconds",
  PLAY_DURATION: "Play Duration",
  PLAY_DURATION_SECONDS: "Play Duration Seconds",

  CLASS_CATEGORY: "Class Category",
  PROVIDER_ID: "Provider ID",
  PROVIDER_NAME: "Provider Name",
  CHANNEL_ID: "Channel ID",
  CHANNEL_Name: "Channel Name",

  LIVE: "Live",
  START_DATE: "Start Date",
  EVENT_ID: "Event ID",

  CATEGORY_ID: "Category Id",
  CATEGORY_Name: "Category Name",
  EVENT_TYPE: "Event type",
};

/*
    CUSTOM_DIMENSION_0 : Client ID
    CUSTOM_DIMENSION_1 : Authenticated
    CUSTOM_DIMENSION_2 : Subscription Status
    CUSTOM_DIMENSION_3 : Subscription Type
    CUSTOM_DIMENSION_4 : Subscription Start Date
    CUSTOM_DIMENSION_5 : Subscription End Date
    CUSTOM_DIMENSION_6 : Subscription Interval
    CUSTOM_DIMENSION_7 : Subscription Interval Count
    CUSTOM_DIMENSION_8 : Access Code
    CUSTOM_DIMENSION_9 : External ID
    */

/// Localytics Profile Attributes Name
export const LocalyticsProfileAttributesName = {
  FIRST_NAME: "First Name", /// string
  LAST_NAME: "Last Name", /// string
  AUTHENTICATED: "Authenticated", ///True/False
  EXTERNAL_ID: "External Id", /// string
  SUBSCRIBER_STATUS: "Subscriber Status", ///UNKNOWN/INACTIVE/ACTIVE
  SUBSCRIPTION_TYPE: "Subscriber Type", ///SSO/FAC/Trial/Paid
  SUBSCRIPTION_START_DATE: "Subscription Start Date", /// string | date
  SUBSCRIPTION_END_DATE: "Subscription End Date", /// string | date
  SUBSCRIPTION_INTERVAL: "Subscription Interval", /// string | number
  SUBSCRIPTION_INTERVAL_COUNT: "Subscription Interval Count", /// string | number
  ACCESS_CODE: "Access Code", ///UNKNOWN/string
};
