import React,{useEffect} from "react";
import Button from "@material-ui/core/Button";
import { Translate } from "../../../../utils/Translate";
import Routes from '../../../../components/Router/Routes'
import {
  clearVideoError
} from "../../../../store/actions/ondemand"
import {  useDispatch } from 'react-redux'
import { useNavigate } from "react-router";

export class Video extends React.Component {
  //constructor(props) {
  //super(props);
  //this.startVideo = React.createRef();
  //}
  componentDidMount() {
    //this.startVideo.current.play();
  }
  render() {
    return (
      <div role="button" aria-label="Video Player" tabIndex="0">
        <video
         
          className="video-js vjs-default-skin"
          controls
          onMouseMove={() => this.props.showleaveBarMethod}
          role="button"
          aria-label="Video Player"
          tabIndex="0"
        >
          <source src={this.props.videourl} type="application/x-mpegURL" />
        </video>
      </div>
    );
  }
}

export function VideoError(props) {
const dispatch = useDispatch()
const navigate = useNavigate();
  useEffect(() => {
    return () => {
      if(props.videoError)
      dispatch(clearVideoError())
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <div className="workOutCompleted">
      <div className="workOutCompletedHolder">
        <p className="workOutCompletedTime">
          {props.videoError ?
            props.videoError : Translate({ id: "Player.VideoError" })}
        </p>
        <Button
          variant="contained"
          color="secondary"
          className="button playerButtons"
          style={{ backgroundColor: localStorage.getItem("clientcolor") }}
          onClick={() => navigate(Routes.homePage)}
          role='button'
          aria-label='From player to homePage'
          tabindex='0'
        >
          <p className="playerButtonText" tabindex='0' role='button'  aria-label='Exit'>{Translate({ id: "Player.Exit" })}</p>
        </Button>
      </div>
    </div>
  );
}
