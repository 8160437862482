import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { Grid, Typography, Button } from '@material-ui/core';
import Filter from 'containers/LiveConnect/filter';
import Workout from 'hoc/WorkoutContainer';
import Routes from 'components/Router/Routes'
import { Translate } from 'utils/Translate';
import InfiniteScroll from "react-infinite-scroller";
import LoadingClassesShimmer from "components/Shimmer/LoadingClassesShimmer";
import { LAZY_LOADING_PAGE_SIZE, subCategoryFilterType, viewAllCategory } from 'utils/constants';
import { isSeamlessMode, getTranslatedFile, getImage, PAGE_CONSTS } from 'utils/utilityfunctions';
import { useCategories } from "hooks/useCategories";
import { useNavigate, useParams } from 'react-router';
import Drawer from 'components/Drawer';
import CategoryDrawer from 'components/Drawers/CategoryDrawer';
import { Skeleton } from '@material-ui/lab';
// import { FirebaseApp } from '../../FirebaseApp';
import {logCategoryViewed} from '../../utils/Localytics'
function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tab-${index}`,
  };
}

const Categories = (props) => {
  const { useCategoriesState, useCategoriesDispatch } = useCategories();
  const { metadata, selectedFilters, tempFilterSearchClasses,
    loading, filteredClassSearchData, filteredSkill,
    filteredDuration, filteredKeywords, filteredSubCategory,
    filteredFocusArea, filteredEquipments, filterSortBy,
    filterSearchText, filteredIntensity, filteredLanguage,
    filteredTopKeywords, pageCount, paginatedData,
    classesSearchData, tempClassSearchData, topKeywords,
    searchedOnDemandClassesObj, isAuthenticated, searchTextLoading } = useCategoriesState();

  const { searchOnDemandClass, setPageCount,
    setPaginatedData, setTempClassSearchData, setFilteredClassSearchData,
    setFilteredTopKeywords, setClassesSearchData, setTopKeywords,
    setKeywords, setSelectedFilters, resetFilters, searchOnDemandClassWithFilter, setSubCategories } = useCategoriesDispatch();

  const [categories, setCategories] = useState([])
  const [classesCount, setClassesCount] = useState('')
  const [categoryId, setCategoryId] = useState(null)
  const [categoryName, setCategoryName] = useState('')
  const [categoryDesc, setCategoryDesc] = useState('')
  const [showNoDataFound, setShowNoDataFound] = useState(false)
  const [hasMoreData, setHasMoreData] = useState(false);
  const [loadMoreAllCalled, setLoadMoreAllCalled] = useState(false)
  const [currentSubCategory, setCurrentSubCategory] = useState("all")
  const [openDrawer, setOpenDrawer] = useState(false)
  const [hovered, setHovered] = useState(false)

  // eslint-disable-next-line no-unused-vars
  const [tabValue, setTabValue] = React.useState(0);
  const navigate = useNavigate();
  const params = useParams();
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const subCategoryAll = "all"
  const from = PAGE_CONSTS.category

  useEffect(() => {
    if (metadata) {
      setCategoryDetailsFromMetaData(metadata, categoryId)
      if (metadata?.categories && metadata.categories.length > 0) {
        setCategories(metadata?.categories)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata])

  const setCategoryDetailsFromMetaData = (metadata, id) => {
    for (let index = 0; index < metadata.categories.length; index++) {
      if (metadata.categories[index].id.toString() === id) {
        setCategoryName(metadata.categories[index].name)
        setCategoryDesc(metadata.categories[index].description)
        logCategoryViewed({categoryId:id,categoryName:metadata.categories[index].name})
        break;
      }
    }
  }
  useEffect(() => {
    setCategoryId(params?.id)
    resetFilterData()
    if (metadata) {
      setCategoryDetailsFromMetaData(metadata, params?.id)
    }
    params?.id=== viewAllCategory.id ? fetchData("") : fetchData(params?.id)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params?.id])

  useEffect(() => {
    handleMouseOut()
  }, [categoryId])

  const fetchData = (searchType, keyword = '') => {
    params?.id === viewAllCategory.id ? setCategoryId(viewAllCategory.id): setCategoryId(searchType)
    if (selectedFilters?.length > 0 || filterSearchText?.length > 0)
      fetchDataByFilters(selectedFilters, true)
    else if (pageCount === 0) {
      searchOnDemandClass(from,searchType, keyword)
    }
  }

  const fetchDataByFilters = (filters, fromNavigation = false, reset = false, fromTopKeyWords = false, subCategory) => {
    setPageCount(0)
    setPaginatedData([])
    if (reset === true || (filterSearchText.length === 0 && filters.length === 0)) {
      resetAllFiltersValues(subCategory)
    }
    else if (filterSearchText.length > 0) {
      fetchDataByFilterSearch(filterSearchText)
    }
    else {
      if (fromNavigation)
        getFilteredData(filteredClassSearchData, filters, fromTopKeyWords)
      else
        getFilteredData(classesSearchData, filters, fromTopKeyWords)
    }
  }

  const getFilteredData = (classesSearchData, filters, fromTopKeyWords) => {
    let tempData = [], skillLevels = [], intensityLevels = [], equipment = '', keyword = '', focusArea = '', classLanguage = '', sortBy = '', subCategory = '', duration = '', mediaType = "";
    for (let j = 0; j < filters.length; j++) {
      switch (filters[j].filterType.toString().toLowerCase()) {
        case `keywords`:
          keyword = filters[j].filterValue
          break;
        case `duration`:
          duration = filters[j].filterValue
          break;
        case `skill level`:
          skillLevels = filters[j].filterValue.split(',');
          break;
        case `focus area`:
          focusArea = filters[j].filterValue
          break;
        case `equipment`:
          equipment = filters[j].filterValue
          break;
        case `intensity`:
          intensityLevels = filters[j].filterValue.split(',');
          break;
        case `class language`:
          classLanguage = filters[j].filterValue;
          break;
        case `sub category`:
          subCategory = filters[j].filterValue;
          break;
        case `sort`:
          sortBy = filters[j].filterValue;
          break;
        case `media type`:
          mediaType = filters[j].filterValue;
          break;
        default:
          return null
      }
    }


    for (let i = 0; i < classesSearchData.length; i++) {
      let allow = true, found = true
      //Keywords
      if (keyword.length > 0 && classesSearchData[i].virtualClass.labels && classesSearchData[i].virtualClass.labels.length > 0) {
        for (let index = 0; index < classesSearchData[i].virtualClass.labels.length; index++) {
          if (!(classesSearchData[i].virtualClass.labels[index].includes(keyword))) {
            found = false
          }
          else {
            found = true
            break;
          }
        }
        if (!found)
          allow = false
      }
      else if (keyword.length > 0 && classesSearchData[i].virtualClass.labels && classesSearchData[i].virtualClass.labels.length === 0) {
        allow = false
      }
      else if (keyword.length > 0 && !classesSearchData[i].virtualClass.labels) {
        allow = false
      }
      //Duration
      if (duration.length > 0 && classesSearchData[i].virtualClass.durationSecond) {
        let timeMin, timeMax;
        switch (duration) {
          case '0,600':  //10 min
            timeMin = 0; timeMax = 600
            break;
          case '601,1200':  //20 min
            timeMin = 601; timeMax = 1200
            break;
          case '1201,1800':   //30 min
            timeMin = 1201; timeMax = 1800
            break;
          case '1801,18000':    //40+min
            timeMin = 1801; timeMax = 18000
            break;
          default:
            return null
        }
        if (!(parseFloat(classesSearchData[i].virtualClass.durationSecond) >= parseInt(timeMin) && parseFloat(classesSearchData[i].virtualClass.durationSecond) <= (timeMax))) {
          allow = false
        }
      }
      else if (duration.length > 0 && !classesSearchData[i].virtualClass.durationSecond) {
        allow = false
      }
      //Skill Levels
      if (skillLevels.length > 0 && classesSearchData[i].virtualClass.skill !== undefined) {
        if (!(classesSearchData[i].virtualClass.skill >= skillLevels[0] && classesSearchData[i].virtualClass.skill <= skillLevels[1])) {
          allow = false
        }
      }
      else if (skillLevels.length > 0 && classesSearchData[i].virtualClass.skill === undefined) {
        allow = false
      }
      //Intensity levels
      if (intensityLevels.length > 0 && classesSearchData[i].virtualClass.intensity !== undefined) {
        if (!(classesSearchData[i].virtualClass.intensity >= intensityLevels[0] && classesSearchData[i].virtualClass.intensity <= intensityLevels[1])) {
          allow = false
        }
      }
      else if (intensityLevels.length > 0 && classesSearchData[i].virtualClass.intensity === undefined) {
        allow = false
      }
      //Class SubCategory
      if (subCategory.length > 0 && classesSearchData[i].virtualClass.classSubCategoryId) {
        if (!(classesSearchData[i].virtualClass.classSubCategoryId === subCategory)) {
          allow = false
        }
      }
      else if (subCategory.length > 0 && !classesSearchData[i].virtualClass.classSubCategoryId) {
        allow = false
      }
      //Class Language
      if (classLanguage.length > 0 && classesSearchData[i].virtualClass.languageName) {
        if (!(classesSearchData[i].virtualClass.languageName.toLowerCase() === classLanguage.toLowerCase())) {
          allow = false
        }
      }
      else if (classLanguage.length > 0 && !classesSearchData[i].virtualClass.languageName) {
        allow = false
      }
      //FocusArea
      if (focusArea.length > 0 && classesSearchData[i].virtualClass.focusArea && classesSearchData[i].virtualClass.focusArea.length > 0) {
        for (let index = 0; index < classesSearchData[i].virtualClass.focusArea.length; index++) {
          if (!(classesSearchData[i].virtualClass.focusArea[index].includes(focusArea))) {
            found = false
          }
          else {
            found = true
            break;
          }
        }
        if (!found)
          allow = false
      }
      else if (focusArea.length > 0 && classesSearchData[i].virtualClass.focusArea && classesSearchData[i].virtualClass.focusArea.length === 0) {
        allow = false
      }
      else if (focusArea.length > 0 && !classesSearchData[i].virtualClass.focusArea) {
        allow = false
      }
      //Equipments
      if (equipment.length > 0 && classesSearchData[i].virtualClass.equipmentNames && classesSearchData[i].virtualClass.equipmentNames.length > 0) {
        for (let index = 0; index < classesSearchData[i].virtualClass.equipmentNames.length; index++) {
          if (!(classesSearchData[i].virtualClass.equipmentNames[index].includes(equipment))) {
            found = false
          }
          else {
            found = true
            break;
          }
        }
        if (!found)
          allow = false
      }
      else if (equipment.length > 0 && classesSearchData[i].virtualClass.equipmentNames && classesSearchData[i].virtualClass.equipmentNames.length === 0) {
        allow = false
      }
      else if (equipment.length > 0 && !classesSearchData[i].virtualClass.equipmentNames) {
        allow = false
      }
      if (mediaType != "" && classesSearchData[i].virtualClass.mediaType) {
        if (!(classesSearchData[i].virtualClass.mediaType == mediaType)) {
          allow = false;
        }
      } else if (mediaType != "" && !classesSearchData[i].virtualClass.mediaType) {
        allow = false;
      }
      if (allow)
        tempData.push(classesSearchData[i])
    }
    !fromTopKeyWords && fetchTopKeywords(tempData)
    setClassesCount(tempData.length)
    if (sortBy.length > 0) {
      let sortedData = tempData.sort((a, b) => {
        if (sortBy === "Newest")
          return new Date(b.virtualClass.scheduleDate) - new Date(a.virtualClass.scheduleDate)
        else
          return new Date(a.virtualClass.scheduleDate) - new Date(b.virtualClass.scheduleDate)
      });
      setTempClassSearchData(sortedData)
    }
    else
      setTempClassSearchData(tempData)

    if (tempData.length === 0)
      setShowNoDataFound(true)
    else
      setShowNoDataFound(false)
    setFilteredClassSearchData(tempData)
    window.scrollTo(0, 0);

  }

  const fetchTopKeywords = (classesSearchData) => {

    let tempTopKeywords = []
    for (let i = 0; i < classesSearchData.length; i++) {
      if (tempTopKeywords.length === 0 && classesSearchData[i].virtualClass.labels.length === 1) {
        tempTopKeywords.push({ keyword: classesSearchData[i].virtualClass.labels[0], count: 1 })
      }
      else {
        if (classesSearchData[i].virtualClass.labels.length === 1) {
          let temp = [...tempTopKeywords], matched = false
          for (let x = 0; x < temp.length; x++) {
            if (tempTopKeywords[x].keyword === classesSearchData[i].virtualClass.labels[0]) {
              tempTopKeywords[x] = { ...tempTopKeywords[x], count: parseInt(tempTopKeywords[x].count) + 1 }
              matched = true
              break;
            }
          }
          if (!matched) {
            tempTopKeywords.push({ keyword: classesSearchData[i].virtualClass.labels[0], count: 1 })
          }
        }
        else {
          let temp = [...tempTopKeywords], matched = false
          for (let j = 0; j < classesSearchData[i].virtualClass.labels.length; j++) {
            matched = false
            for (let y = 0; y < temp.length; y++) {
              if (tempTopKeywords[y].keyword === classesSearchData[i].virtualClass.labels[j]) {
                tempTopKeywords[y] = { ...tempTopKeywords[y], count: parseInt(tempTopKeywords[y].count) + 1 }
                matched = true
                break;
              }
            }
            if (!matched) {
              tempTopKeywords.push({ keyword: classesSearchData[i].virtualClass.labels[j], count: 1 })
            }
          }
        }
      }
    }

    let sortTopKeywords = tempTopKeywords.sort(function (a, b) {
      return b.count - a.count
    })
    let sortedTopKeywords = []
    for (let z = 0; z < sortTopKeywords.length; z++) {
      sortedTopKeywords.push(sortTopKeywords[z].keyword)
    }
    if (sortedTopKeywords.length > 3)
      sortedTopKeywords = sortedTopKeywords.splice(0, 3)

    let temp = [...topKeywords]
    let prevTopKeywords = filteredTopKeywords.length > 0 ? filteredTopKeywords : temp.splice(0, 3)
    let tempsortedTopKeywords = [...sortedTopKeywords]
    let tempprevTopKeywords = [...prevTopKeywords]
    let sortedTKeywordsByWord = tempsortedTopKeywords.sort()
    let sortedPrevTopKeywordsByWord = tempprevTopKeywords.sort()
    // eslint-disable-next-line eqeqeq
    if (JSON.stringify(sortedTKeywordsByWord) != JSON.stringify(sortedPrevTopKeywordsByWord)) {
      setFilteredTopKeywords(sortedTopKeywords)
    }
  }

  useEffect(() => {
    if (searchedOnDemandClassesObj) {
      setClassesSearchData(searchedOnDemandClassesObj.items)
    }
    if (searchedOnDemandClassesObj) {
      if (selectedFilters?.length === 0)
        setClassesCount(searchedOnDemandClassesObj.items.length)
      setTopKeywords(searchedOnDemandClassesObj.metaData, searchedOnDemandClassesObj.metaData.topLabels)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchedOnDemandClassesObj])

  useEffect(() => {
    if (tempFilterSearchClasses && filterSearchText.length > 0) {
      setClassesCount(tempFilterSearchClasses.items.length)
      setTempClassSearchData(tempFilterSearchClasses.items)
      fetchTopKeywords(tempFilterSearchClasses.items)
      if (tempFilterSearchClasses.items.length === 0)
        setShowNoDataFound(true)
      else
        setShowNoDataFound(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tempFilterSearchClasses])

  const fetchDataByTopKeywords = (filterValue, filterType = 'Keywords', formTopKeyWords = false) => {
    setPageCount(0)
    setPaginatedData([])
    let tempFilters = [...selectedFilters], sameValueSelected = false
    if (selectedFilters.length === 0) {
      tempFilters.push({ filterType, filterValue })
      setKeywords(filterValue)
    }
    else {
      for (let index = 0; index < selectedFilters.length; index++) {
        if (selectedFilters[index].filterType === filterType) {
          if (selectedFilters[index].filterValue === filterValue)
            sameValueSelected = true
          tempFilters.splice(index, 1);
        }
      }
      if (!sameValueSelected) {
        tempFilters.push({ filterType, filterValue })
        setKeywords(filterValue)
      }
      else {
        setKeywords('')
      }
    }
    setSelectedFilters(tempFilters)
    fetchDataByFilters(tempFilters, false, false, formTopKeyWords)
  }

  const resetAllFiltersValues = (subCategory) => {
    const subcategory = subCategory ?? currentSubCategory
    setClassesCount(classesSearchData?.length)
    if (subcategory !== subCategoryAll) {
      let tempFilters = [{ filterType: subCategoryFilterType, filterValue: subcategory }]
      resetFilters()
      setSelectedFilters(tempFilters)
      getFilteredData(classesSearchData, tempFilters, false)
    }
    else resetFilters()
    if (classesSearchData?.length > 0)
      setShowNoDataFound(false)
  }

  const fetchDataByFilterSearch = (searchText, fromSearch = true, subCategory) => {
    const subcategory = subCategory ?? currentSubCategory ?? filteredSubCategory
    const subCategoryId = subcategory === subCategoryAll ? "" : subcategory
    setShowNoDataFound(false)
    searchOnDemandClassWithFilter(from,params?.id, filteredKeywords, filteredFocusArea, filteredSkill, filteredEquipments, filteredDuration, filteredIntensity, searchText, filterSortBy, filteredLanguage, subCategoryId, fromSearch);
  }

  const onClickHandler = data => {
    navigate( Routes.onDemandClassPlayerRoute + data.tag,
              { state: { selectedItem: data }} );
  };

  window.onscroll = () => {
    if (isSeamlessMode()) {
      if (document.querySelector(".staticBlockEw")) {
        if (window.scrollY > 96) {
          document.querySelector(".staticBlockEw").className = "staticBlockEw scroll";
        } else {
          document.querySelector(".staticBlockEw").className = "staticBlockEw";
        }
      }
    }
    else {
      if (document.querySelector(".staticBlock")) {
        if (window.scrollY > 96) {
          document.querySelector(".staticBlock").className = "staticBlock scroll";
        } else {
          document.querySelector(".staticBlock").className = "staticBlock";
        }
      }
    }
  }

  const resetFilterData = () => {
    fetchDataByFilters([], false, true)
  }

  const loadMore = () => {

    let toFilterClasses = []
    if (tempClassSearchData.length === 0 && selectedFilters.length === 0) {
      toFilterClasses = classesSearchData
    }
    else
      toFilterClasses = tempClassSearchData
    setTimeout(() => {
      if (!loadMoreAllCalled) {
        setLoadMoreAllCalled(true)
        if (pageCount < toFilterClasses.length) {
          let data = toFilterClasses.slice(0, pageCount + LAZY_LOADING_PAGE_SIZE)
          setPageCount(pageCount + LAZY_LOADING_PAGE_SIZE)
          setPaginatedData(data)
        }
        else {
          setHasMoreData(false)
        }
      }
      else {
        setLoadMoreAllCalled(false)
      }
    }, 1000);
  }

  useEffect(() => {
    if (tempClassSearchData && tempClassSearchData?.length === 0 && selectedFilters?.length === 0 && classesSearchData && classesSearchData.length > 0 && pageCount === 0) {
      setPaginatedData(classesSearchData.slice(0, LAZY_LOADING_PAGE_SIZE))
    }
    else if (tempClassSearchData && tempClassSearchData.length > 0 && (filterSearchText.length > 0 || selectedFilters.length > 0)) {
      setPaginatedData(tempClassSearchData.slice(0, LAZY_LOADING_PAGE_SIZE))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classesSearchData, tempClassSearchData])


  useEffect(() => {
    if ((tempClassSearchData && ((tempClassSearchData.length > 0 || (classesSearchData && classesSearchData.length > 0))) && paginatedData?.length > 0)) {
      let allClasses = tempClassSearchData.length > 0 ? tempClassSearchData : classesSearchData
      if (allClasses.length <= paginatedData?.length) {
        setHasMoreData(false)
      }
      else {
        setHasMoreData(true)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paginatedData])

  const checkFavStatus = (favoriteId) => {
    let classes = JSON.parse(JSON.stringify(paginatedData));
    let classIndex = classes.findIndex(particularclass => particularclass.classTag === favoriteId)
    if (classIndex !== -1) {
      classes[classIndex].virtualClass.favourite = !classes[classIndex].virtualClass.favourite
      setPaginatedData(classes)
    }

    if (tempClassSearchData.length === 0 && selectedFilters.length === 0 && classesSearchData.length > 0 && pageCount === 0) {
      let classIndex = classesSearchData.findIndex(particularclass => particularclass.classTag === favoriteId)
      if (classIndex !== -1) {
        classesSearchData[classIndex].virtualClass.favourite = !classesSearchData[classIndex].virtualClass.favourite
      }
    }
    else if (tempClassSearchData.length > 0 && (filterSearchText.length > 0 || selectedFilters.length > 0)) {
      let classIndex = tempClassSearchData.findIndex(particularclass => particularclass.classTag === favoriteId)
      if (classIndex !== -1) {
        tempClassSearchData[classIndex].virtualClass.favourite = !tempClassSearchData[classIndex].virtualClass.favourite
      }
    }
  }

  const getCategoryImage = () => {
      const categoryImage = (categories.find((cat) => cat.id == categoryId))?.imageLink 
      let backUpImage
      try {
        backUpImage = require("../../assets/images/view-all.png")
      }catch {
        backUpImage = require("../../assets/images/home.jpg")
      }
      return categoryImage ?? backUpImage
  }


  const getSubCategoryData = (subCategory) => {
    setSubCategories(subCategory)
    if (subCategory === subCategoryAll){
      let tempFilters = selectedFilters.filter((filter)=> filter.filterType !== subCategoryFilterType)
      filterSearchText.length > 0 ? fetchDataByFilterSearch(filterSearchText, true, "") : fetchDataByFilters(tempFilters, false,false,false,subCategory)
    }else{
      if (searchedOnDemandClassesObj && searchedOnDemandClassesObj?.items) {
        let filterTypeFound = false
        let tempFilters
        for (let index = 0; index < selectedFilters.length; index++) {
          if (selectedFilters[index].filterType === subCategoryFilterType) {
            filterTypeFound = true
            if (selectedFilters[index].filterValue !== subCategory)
              selectedFilters[index].filterValue = subCategory
            tempFilters = [...selectedFilters]
          }
        }
        if (!filterTypeFound){
          filterTypeFound = true
          tempFilters = [...selectedFilters, { filterType: subCategoryFilterType, filterValue: subCategory }]
        }
        setSelectedFilters(tempFilters)
        filterSearchText.length > 0 ? fetchDataByFilterSearch(filterSearchText, true, subCategory) : fetchDataByFilters(tempFilters, false)
      }
    }
  };

  const handleCategoryChange = (id) => {
    if (id.toString() !== categoryId) {
      setClassesSearchData([])
      setTopKeywords([])
      setCurrentSubCategory(subCategoryAll)
      resetFilters()
      setShowNoDataFound(false)
      setHasMoreData(false)
      navigate(`${Routes.categories}${id}`)
    }
  }

  const categoryMatch = () => {
    //check if the sub category are of the currently opened category
    const currentCategoryName = metadata?.categories?.find((ele) => ele.id == categoryId)?.name 
    if (categoryId !== viewAllCategory.id){
    return currentCategoryName === searchedOnDemandClassesObj?.metaData.classCategories[0] ? true : false
    }
  }

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
    setTimeout(() => {
      setOpenDrawer(false)
    }, 500);
  };

  const handleMouseOver = () => {
    setHovered(true)
  }

  const handleMouseOut = () => {
    setHovered(false)
  }

  return (
    <div className="category-page align-left ">

      <div className="fixedWidthContainer container main-margin" style={{ backgroundImage: `url(${getCategoryImage(categoryId)})` }}>
        <div className="banner-overlay category-overlay">
          <div className="category-banner ">
            <div className={`d-flex ${hovered && "highlight-hover"}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut} >
              <Drawer children={<CategoryDrawer metadata={metadata} handleClick={handleCategoryChange} />} drawerOpen={openDrawer} itemHovered={hovered}/>
              <Typography className={`m-t-xs-5 browseCategory menu-heading makeTextClickable`} onClick={handleDrawerOpen}  >
                {Translate({ id: `ClassListViews.Categories`, defaultMessage: "Categories"  })}
              </Typography>
            </div>
          </div>
          <div style={{ zIndex: 1 }} className="category-page-heading chnl-detail-desc text-white">
            {categoryId !== viewAllCategory.id && !metadata && <>
              <Skeleton role="presentation" variant="text" width={'50%'} height={30} />
              <Skeleton role="presentation" variant="text" width={'70%'} height={60} />
              </>}
            <Typography variant="h1" className="m-t-xs-5" data-testid="category-title">
              {categoryId === viewAllCategory.id ?  Translate({ id: `ClassListViews.ViewAll` }) : 
              (categoryName !== "" && categoryId !== viewAllCategory.id) ?   Translate({ id: `categoryName.${categoryId}` }) : ''}
            </Typography>
            <Grid container direction="row">
              <Grid item lg={12} md={12} className="channelDescription category-desc">
                <Typography className="padding16 font-h6 MuiTypography-h6" data-testid={`category-desc-${categoryId}`} >
                  {categoryId === viewAllCategory.id ? Translate({ id: `ClassListViews.ViewAllDesc` }) : 
                    (categoryDesc !== "" && categoryId !== viewAllCategory.id) ? Translate({ id: `categoryDescription.${categoryId}`, defaultMessage: ' ' }) : ""}
                </Typography>
              </Grid>
              {!searchedOnDemandClassesObj && <Skeleton role="presentation" variant="text" width={'80%'} height={30} className="browseCategory flexNoWrap sub-categories" />}
            </Grid>
            {categoryId !== viewAllCategory.id && searchedOnDemandClassesObj?.metaData?.classSubCategoryIds?.length > 0 && categoryMatch() && <Tabs
              data-testid="sub-category-tabs"
              value={tabValue}
              onChange={handleChange}
              //indicatorColor="none"
              // variant="scrollable"
              scrollButtons="off"
              TabIndicatorProps={{ style: { display: "none" } }}
              aria-label="force tabs example"
              alignitems="left" className="browseCategory sub-categories">
              <Tab label={Translate({ id: `liveStream.All` , defaultMessage: "All" })} {...a11yProps(subCategoryAll)}
                aria-selected={subCategoryAll == currentSubCategory}
                onClick={() => {
                  setCurrentSubCategory(subCategoryAll)
                  getSubCategoryData(subCategoryAll)}
                }
                className={`makeTextClickable single-truncate tab-custom ${subCategoryAll == currentSubCategory ? "dynamiclinks Mui-selected" : ""}`} />
              {searchedOnDemandClassesObj.metaData.classSubCategoryIds.map((subCategory, index) =>
                  <Tab role="heading" aria-level="2" data-testid={subCategory} key={index} label={Translate({ id: `subcategories.${subCategory}` })} {...a11yProps(subCategory)}
                  onClick={() => {
                    if ("ViewAll" !== categoryId) {
                      setCurrentSubCategory(subCategory)
                      getSubCategoryData(subCategory);
                    }
                  }}
                  // eslint-disable-next-line
                  aria-selected={subCategory == currentSubCategory}
                  className={`makeTextClickable single-truncate tab-custom ${subCategory == currentSubCategory ? "dynamiclinks Mui-selected" : ""}`} />
                )}
            </Tabs>}
          </div>
        </div>
      </div>


      <div className='main-page-container list-container-gap'>
        <Grid container justifyContent="flex-start" className="topKeySpace">
          <Filter
            showFLLOnDemand={false}
            fetchData={fetchData}
            categoryName={categoryId}
            subCategory={currentSubCategory}
            topKeywords={showNoDataFound ? [] : filteredTopKeywords?.length > 0 ? filteredTopKeywords : topKeywords}
            initialFilterMetaData={searchedOnDemandClassesObj && searchedOnDemandClassesObj.metaData}
            fetchDataByFilters={fetchDataByFilters}
            classesCount={classesCount}
            fetchDataByTopKeywords={fetchDataByTopKeywords}
            fetchDataByFilterSearch={fetchDataByFilterSearch}
          />
        </Grid>


        {!showNoDataFound &&
          <InfiniteScroll
            pageStart={0}
            datalength={LAZY_LOADING_PAGE_SIZE}
            loadMore={loadMore}
            hasMore={hasMoreData}
            loader={hasMoreData && !loading ? <LoadingClassesShimmer key={Math.random()} 
            myClass="live-stream-loader"
            totalCards={paginatedData?.length}
            /> : null}
          >
            <div className="collectionShimmer">
              {
                loading || searchTextLoading ? <LoadingClassesShimmer key={Math.random()}  myClass="live-stream-loader"    totalCards={paginatedData?.length}/> : <div className='list list-live-page'>
                  {paginatedData?.length > 0 && paginatedData.map((item, index) => (
                    <Workout
                      cardType="channel-page-card"
                      collectionType="featured"
                      isSearchPageCard={false}
                      displayDate={item.virtualClass.scheduleDate}
                      trailerLinkWeb={item.virtualClass.trailerLinkWeb}
                      trailerLinkMobile={item.virtualClass.trailerLinkMobile}
                      isSliderWorkout={true}
                      history={props.history}
                      showFav={isAuthenticated ? true : false}
                      thumbnail={`${item.virtualClass.imageLink}`}
                      equipmentTypes={item.virtualClass.equipmentTypes}
                      equipmentNames={item.virtualClass.equipmentNames}
                      isFav={item.virtualClass.favourite}
                      title={item.virtualClass.className}
                      duration={item.virtualClass.durationSecond
                        ? Math.ceil(item.virtualClass.durationSecond / 60)
                        : 55}
                      description={item.virtualClass.classDescription}
                      show={true}
                      calories={item.virtualClass.calorieBurn}
                      id={item.virtualClass.tag}
                      classCategory={item.virtualClass.classCategory}
                      mediaType={item.virtualClass.mediaType}
                      key={categoryId + index}
                      clicked={() => onClickHandler(item.virtualClass)}
                      collectionName={categoryId}
                      level={item?.virtualClass?.skill ? item.virtualClass.skill : item?.skill}
                      favCallback={() => { checkFavStatus(item.virtualClass.tag) }}
                      isUnlocked={item.virtualClass.isUnlocked}
                    />
                  ))}
                  </div>
              }
            </div>
          </InfiniteScroll>
        }

        {
          showNoDataFound && <Grid container justifyContent="flex-start" spacing="4" >
            <Typography className="text-gray padding16 m-t-55 m-t-xs-32"
              style={{ textAlign: 'center', width: '100%' }}
            >
              {Translate({ id: "filter.NoClassesFound" })}
            </Typography>
            <div className="padding16 m-t-xs-15 m-t-30" style={{ textAlign: 'center', width: '100%' }}>
              <Button
                type="submit"
                variant="contained"
                color="secondary"
                className="button m-t-15 btn-default"
                style={{ height: 48, borderRadius: 35, }}
                onClick={resetFilterData}
                role='button'
                aria-label='Reset Filter'
                tabIndex='0'
              >
                <Typography variant="button" tabIndex='0' style={{ color: "secondary" }} role='button' aria-label='Show all classes'>
                  {Translate({ id: "filter.ShowAllClasses" })}
                </Typography>
              </Button>
            </div>
          </Grid>
        }
      </div >
    </div>
  );
}



export default Categories;
