import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import CardShimmer from './CardShimmer';

const LiveScheduleShimmer = (props) => {
    return (
        <div data-testid={"liveScheduleShimmer"} className="liveShimmer mobileScroll m-t-15">
            <Skeleton role="presentation" variant="text" width={'30%'} height={25} />
            <Skeleton role="presentation" variant="text" width={'40%'} height={15} />
            <CardShimmer noOfCards={[1,2,3,4,5]} footer={false}/>
        </div>
    );
}
export default LiveScheduleShimmer;
