import React from "react";
function IconList(props) {
  const color = props.color;
  return (
    <svg
      className="icon-svg-list"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
    >
      <defs>
        <path
          d="M4.5 14h2c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm0 5h2c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm0-10h2c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1h-2c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm5 5h10c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1h-10c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm0 5h10c.55 0 1-.45 1-1v-2c0-.55-.45-1-1-1h-10c-.55 0-1 .45-1 1v2c0 .55.45 1 1 1zm-1-13v2c0 .55.45 1 1 1h10c.55 0 1-.45 1-1V6c0-.55-.45-1-1-1h-10c-.55 0-1 .45-1 1z"
          id="ddx12atc0a"
        />
      </defs>
      <g fill="none" fill-rule="evenodd">
        <use xlinkHref="#g5rgkj258a" fill={color} />
      </g>
    </svg>
  );
}

export default IconList;
