import React from "react"

function StarColor(props){
    const color = props.color

    return (
      <div tabIndex="0"  className={`rating-star ${props.cls}`}>
        <svg
          className="icon-svg-play-list"
          id={props.id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
        >
          <defs>
            <path
              d="m16 23.36 5.533 3.346c1.014.613 2.254-.293 1.987-1.44l-1.467-6.293 4.894-4.24c.893-.774.413-2.24-.76-2.334l-6.44-.546-2.52-5.947c-.454-1.08-2-1.08-2.454 0l-2.52 5.933-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.294c-.267 1.146.973 2.053 1.987 1.44L16 23.359z"
              id="0xi680phpa"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <use xlinkHref="#0xi680phpa" fill={color} />
          </g>
        </svg>
      </div>
    );

}

export default StarColor
