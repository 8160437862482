import React from "react";
import Workout from "hoc/WorkoutContainer";
import IconRigntArrow from "CommonImages/icon-rightArrow";
import config from "../../assets/config.json";
import { useMySubscriptionList } from "hooks/useMySubscriptionList";
import { Typography } from "@material-ui/core";
import { Translate } from "utils/Translate";
import {
  handleClassClick,
  isNonEmptyArrayOrObject,
} from "utils/utilityfunctions";
import { useNavigate } from "react-router";
import Routes from "components/Router/Routes";
import "./MySubscription.scss"
import { subscriptionTypes } from "utils/constants";

const MySubscriptionList = React.memo(function MySubscriptionList(props) {
  const navigate = useNavigate()
  const { useMySubscriptionListState } = useMySubscriptionList();
  const { mySubscriptions, isLoading } = useMySubscriptionListState();
  const Subscriptions = mySubscriptions?.data;

  const handleClick = (subscription) => {
    const collectionCount = subscription.bundleItemDetails.collections.count;
    const collectionTag = subscription.bundleItemDetails.collections.tag;
    const bundleName = subscription.title;
    const bundleTag = subscription.subsPackageId;
    const bundleDescription = subscription.description;

    if (collectionCount === 1) {
        navigate(Routes.bundleDetail + bundleTag +"/"+collectionTag, {
          state: {
            bundleName: bundleName,
            bundleDescription: bundleDescription,
          },
        });
    } else {
      navigate(Routes.bundleDetail + bundleTag);
    }
  }

  

 const isAllInSubscription = (subscription) => {
   const bundleType = subscription.subscriptionType.toLowerCase();
   if (bundleType == subscriptionTypes.ALLINONE) {
     return true;
   } else return false;
 }; 

 const allInMessage = (subscription) => {
    return (
      <>
        <div
          className="align-left d-flex makeTextClickable m-t-12 collection-caption-cont subtitle-m-b-8"
        >
          <h6 className=" collection-caption align-left makeTextClickable ">
            {subscription.title}
          </h6>
          <IconRigntArrow
            id="arrow-2"
            color={config.primaryColor}
            cls={"arrow-subscription"}
          />
        </div>
        <h6 className="text-gray font-h6 align-left display-mobile">
          {subscription.description}
        </h6>
      </>
    );
 }



  const homeMySubscription =
    !isLoading && !isNonEmptyArrayOrObject(Subscriptions) ? (
      <div className="no-classes-found m-t-0">
        <Typography variant="body2" className="text-gray">
          {Translate({
            id: "ClassListViews.noPackageSubscribed",
            defaultMessage: "You currently have no active subscriptions.",
          })}
        </Typography>
        <span> </span>
        <Typography variant="body2">
          <a href="/contact" className="dynamiclinks">
            {Translate({ id: "footer.contactUs" })}
          </a>
        </Typography>
      </div>
    ) : (Subscriptions?.map((subscription, index) =>
        isAllInSubscription(subscription) ? (
          allInMessage(subscription)
        ) : (
          <>
            <div
              className="align-left d-flex makeTextClickable m-t-12 collection-caption-cont subtitle-m-b-8"
              onClick={() => handleClick(subscription)}
              key={index}
              role='button'
              aria-label='Subscription'
              tabIndex="0"
            >
              <h6 className=" collection-caption align-left makeTextClickable ">
                {subscription.title}
              </h6>
              <IconRigntArrow
                id="arrow-2"
                color={config.primaryColor}
                cls={"arrow-subscription"}
              />
            </div>

            <div
              data-testid={"subscriptionCards"}
              className={`list ${props.list}`}
            >
              {subscription.items?.map((data, index) => (
                <Workout
                  cardType={"live-channel-collection"}
                  collectionType={"featured"}
                  thumbnail={`${data.imageLink}`}
                  title={data.className}
                  duration={
                    data.durationSecond
                      ? Math.ceil(data.durationSecond / 60)
                      : 55
                  }
                  level={data.skill}
                  displayDate={data.scheduleDate}
                  show={true}
                  calories={data.calorieBurn}
                  id={data.tag}
                  classCategory={data.classCategory}
                  isUnlocked={data.isUnlocked}
                  clicked={() =>
                    handleClassClick(data.tag, data.isUnlocked, navigate)
                  }
                  favDisabled
                  navigate={navigate}
                />
              ))}
            </div>
          </>
        )
      )
    );

  return Subscriptions && homeMySubscription;
});

export default MySubscriptionList;
