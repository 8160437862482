import React from 'react';
import { useNavigate } from 'react-router-dom';
import Routes from 'components/Router/Routes';
import CheckIcon from 'CommonImages/CheckIcon';
import config from 'assets/config.json';
import { Translate } from 'utils/Translate';

const ResetPasswordSuccess = () => {
    const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate(Routes.signinPage);
    };

    return (
        <div className='pass-reset-sucess-page-container'>
            <CheckIcon color={config.primaryColor} />
            <h1>{Translate({ id: "ForgetPassword.Success"})}</h1>
            <a className="headerLinks-fitness dynamiclinks" onClick={handleLoginRedirect} role='button' aria-label="Log in">{Translate({ id: "layout.SignIn" })}</a>
        </div>
    );
};

export default ResetPasswordSuccess;