import React from "react"

function IconPlay(props) {
    const opacity = props.opacity
    const classes = props.cls
    const playTrack = props.playTrack

    return (
        <div className={classes} onClick={() => playTrack()} role='button' aria-label='Play' tabIndex='0'>
            <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                <defs>
                    <path d="M9.237 9.093v13.814a1.33 1.33 0 0 0 2.053 1.12l10.853-6.907c.827-.52.827-1.72 0-2.253L11.29 7.973a1.33 1.33 0 0 0-2.053 1.12z" id="waz2j6uk7a" />
                </defs>
                <g fill="none" fill-rule="evenodd">
                    <mask id="hig5f53oub" fill="#fff">
                        <use xlinkHref="#waz2j6uk7a" />
                    </mask>
                    <g mask="url(#hig5f53oub)" fill="#000" fill-opacity={opacity}>
                        <path d="M0 0h32v32H0z" />
                    </g>
                </g>
            </svg>
        </div>
    )
}

export default IconPlay