import React from "react"

function StarHalf(props){
 const color= props.color 
    return (
      <div tabIndex="0" className={`rating-star icon-svg-star ${props.cls}`}>
        <svg
          className="icon-svg-play-list"
          id={props.id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
        >
          <defs>
            <path
              d="m26.198 12.393-6.453-.56-2.52-5.934c-.454-1.08-2-1.08-2.454 0l-2.52 5.947-6.44.547c-1.173.093-1.653 1.56-.76 2.333l4.894 4.24-1.467 6.293c-.267 1.147.973 2.054 1.987 1.44l5.533-3.333 5.533 3.347c1.014.613 2.254-.294 1.987-1.44l-1.467-6.307 4.894-4.24c.893-.773.426-2.24-.747-2.333zm-10.2 8.48v-12.4l2.28 5.386 5.84.507-4.427 3.84 1.334 5.707-5.027-3.04z"
              id="tllftr9oya"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <use xlinkHref="#tllftr9oya" fill={color} />
          </g>
        </svg>
      </div>
    );
}

export default StarHalf
