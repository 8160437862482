import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";
import "./CarouselSliderComponent.scss";
import IconRightArrowInl from "CommonImages/icon-rightArrowInline";
import config from "../../assets/config.json";


const AUTO_PLAY = true;
const INTERVAL = 3000;
const TIMEOUT = 2000;
const ANIMATION = "slide";
const NAV_BUTTONS_ALWAYS_VISIBLE = false;

function CarouselSliderComponent({ props,handleClick}) {
  return (
    <div className="carousel" data-testid={"carousel"}>
      <Carousel
        autoPlay={AUTO_PLAY}
        interval={INTERVAL}
        timeout={TIMEOUT}
        animation={ANIMATION}
        cycleNavigation={true}
        navButtonsAlwaysVisible={NAV_BUTTONS_ALWAYS_VISIBLE}
        navButtonsProps={{ style: { display: "none" } }}
        activeIndicatorIconButtonProps={{ style: { color: config?.primaryColor } }}
        nextIndicatorIconButtonProps={{ style: { color: config?.primaryColor } }}
        prevIndicatorIconButtonProps={{ style: { color: config?.primaryColor } }}
        indicatorContainerProps={{ className: "indicator-container" }}
      >
        {props?.map((item, index) => (
          <Paper key={index} className="paper">
            <div className="carousel-item-overlay">
              <div  className={`${item?.profileImage ? 'carousel-details-profile' : 'carousel-details'}`} >
                <div className="profile-item-name" >{item?.profileImage ? (
              <img src={item?.profileImage} className="carousel-profileImg" alt="carousel-profileImg" aria-label="carousel-profileImg" role="button" tabIndex={"0"}/>
            ) : null} <span className="carousel-item-name" role="heading" aria-level={2}>{item?.name}</span></div>
                <div className="carousel-item-description">{item?.description}</div>
                <div className="carousel-item-visit"  onClick={() => {handleClick(item?.tag)}} role="button"tabIndex={"0"}>
                  {item?.visit}
                  <span
                    className="right-arrow-icon"
                  >
                    <IconRightArrowInl color={config?.primaryColor} cls="arrow-field arrow-size carosel-arrow" />
                  </span>
                </div>
              </div>
            </div>
            <img
              src={item?.image}
              alt={item?.name}
              style={{objectFit: "cover",borderRadius:"0px", objectPosition: "100% 0%",width:"100%" }}
              className="carousel-image"
            />
          </Paper>
        ))}
      </Carousel>
    </div>
  );
}

export default CarouselSliderComponent;
