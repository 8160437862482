import React from "react";
import PropTypes from "prop-types";
import CustomDialog from "../../../components/Modal/Modal";
import Spinner from "../../../components/spinner/spinner";
import { Translate } from "../../../utils/Translate";
import { getTranslatedFile } from "../../../utils/utilityfunctions";
import { Button, TextField, Typography } from "@material-ui/core";
const PlayListEditModal = (props) => {
  const { handleClose, title, description, savePlayList, loading } = props;
  const [playListInfo, setListInfo] = React.useState({
    title,
    description,
    submitBtnDisable: true,
  });
  const translatedFile = getTranslatedFile();
  const handelText = (e) => {
    if (e.target.value.length < 3) {
      setListInfo((_) => ({ ..._, submitBtnDisable: true }));
    } else setListInfo((_) => ({ ..._, submitBtnDisable: false }));
  };
  const getInput = () => ({
    titleEle: document.querySelector(".play-list-edit-modal-container input"),
    descEle: document.querySelector(".play-list-edit-modal-container textarea"),
  });
  const handelDisable = () => {
    const { titleEle, descEle } = getInput();
    if (titleEle?.value.length < 3) return true;
    if (
      (title === titleEle?.value && description === descEle?.value) ||
      titleEle?.value === undefined
    )
      return true;
    if (loading) return true;
    return false;
  };
  const submitInfo = () => {
    const { titleEle, descEle } = getInput();
    savePlayList(titleEle?.value, descEle?.value);
  };
  return (
    <CustomDialog
      dialogId={"edit-playlist"}
      open
      cancelicon
      playList
      handleDialogClose={handleClose}
      title={Translate({
        id: "Playlist.EditPlaylist",
        defaultMessage: "Edit playlist",
      })}
      aria-label="Edit playlist"
    >
      <div
        className={
          loading
            ? "play-list-edit-modal-container loading"
            : "play-list-edit-modal-container"
        }
      >
        {loading && <Spinner />}
        <div className="play-list-edit-modal-text">
          <TextField
            aria-label="title"
            defaultValue={title}
            onChange={handelText}
            inputProps={{ "aria-label": "title", maxLength: 40 }}
            maxLength="40"
            className="play-list-edit-text"
            label={translatedFile?.Playlist?.EnterName ?? "Enter Title"}
          />
        </div>
        <div className="play-list-edit-modal-desc">
          <TextField
            aria-label="description"
            defaultValue={description}
            onChange={handelText}
            inputProps={{ "aria-label": "description", maxLength: 500 }}
            maxLength="500"
            className="play-list-edit-desc"
            label={translatedFile?.Playlist?.Description ?? "Description"}
            multiline
            minRows={10}
            maxRows={15}
          />
        </div>
        <div className="text-center padding16 edit-button">
          <Button
            size="small"
            type="submit"
            color="secondary"
            className="button btn-default edit-modal-button add-playlist-popup-btn"
            variant="contained"
            onClick={submitInfo}
            style={{ marginTop: "60px" }}
            loading={loading}
            disabled={handelDisable()}
            role="button"
            aria-label="Save play list"
            tabIndex="0"
          >
            <Typography
              className="MuiTypography-root MuiTypography-h5"
              aria-label="Save play list"
            >
              {Translate({ id: "Subscription.Save", defaultMessage: "Save" })}
            </Typography>
          </Button>
        </div>
      </div>
    </CustomDialog>
  );
};

PlayListEditModal.defaultProps = {
  handleClose: () => {},
  savePlayList: () => {},
  loading: false,
};
PlayListEditModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  savePlayList: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export { PlayListEditModal };
