import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useHandelSeekBar } from './InternetSpeedMeter';
// import { getPosterSize } from 'utils/utilityfunctions'
const VideoPlayer = props => {
    const {
      startVideo,
      hideControls,
      file,
      autostart,
      initPlayer,
      image,
      eventId,
      hideFullScreen,
      playList,
      tracks
    } = props;
    const JP = window.jwplayer;
    // const {getVideoDetails} =  useInternetSpeedMeter({imageUrl:image,eventId});
    let player=null;
    const loopEnabled = localStorage.getItem("isLoop") !== null ? JSON.parse(localStorage.getItem("isLoop")):false;
    const playerInit =  useCallback(() => {
        try {
            if(JP && player === null){
                // eslint-disable-next-line react-hooks/exhaustive-deps
                player = JP(eventId).setup({
                  ...(playList &&
                    playList.hasOwnProperty("playlist") && {
                      playlist: playList.playlist,
                    }),
                  ...(playList &&
                    playList.hasOwnProperty("file") && {
                      file: playList.file,
                    }),
                  ...(playList &&
                    playList.hasOwnProperty("image") && {
                      image: playList.image,
                    }),
                  ...(playList && playList.hasOwnProperty("playlist") && {
                    autostart: true,
                  }),
                  ...(!playList?.hasOwnProperty("playlist") && {
                    autostart: autostart,
                    file: file,
                  }),
                  events: {
                    seeked: () => {
                      console.log("SEEKED");
                    },
                    ready: () => {
                      hideControls && (useHandelSeekBar.Video = null);
                      handelRightClick(player);
                      hideControls && handelMenuBtns(player);
                      
                    },
                    beforePlay: () => {
                      handelRightClick(player);
                    },
                    play: () => {
                      hideControls && handelMenuBtns(player);
                      hideFullScreen && handelMenuBtnFullScreen(player);
                      if (player.getState() === "PLAYING") {
                        player.hideControls();
                      }
                      if (
                        hideControls &&
                        useHandelSeekBar.isIos &&
                        useHandelSeekBar.Video === null
                      )
                        useHandelSeekBar.handelEvents(player.getContainer());
                      // getVideoDetails(player)
                    },
                    displayClick: function () {
                      // if(hideControls){
                      //     setTimeout(()=>{
                      //     this.play()
                      //     },150)
                      // }
                    },
                    pause: () => {
                      // player.setControls(false)
                    },
                    fullscreen: () => {
                      if (hideControls && useHandelSeekBar.isIos) {
                        if (player.getFullscreen()) {
                          console.log("in Fullscreen");
                          useHandelSeekBar.addEvents();
                        } else {
                          console.log("not in Fullscreen");
                          //useHandelSeekBar.removeEvents();
                        }
                      }
                    },
                  },
                  tracks: tracks ,
                  ...(loopEnabled && { repeat: loopEnabled }),
                });
                return player;
            }
            else{
                return null;
            }
        } catch (error) {
            console.log(error.message);
            console.log(error.name);
            return null; 
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JP,initPlayer]);

    const handelRightClick = (Player)=>{
        const videoEle = Player.getContainer();
        videoEle.addEventListener("contextmenu",()=>{
            const menuEle = videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset")[0];
            menuEle.style.display="none";
            return false;
        });
        // videoEle.querySelectorAll("ul.jw-rightclick-list.jw-reset>li:first-child")[0].style.display="none";
    }
    const handelMenuBtnFullScreen = (Player) =>{
        try {
            const videoEle = Player.getContainer();
            const fullscreen =  videoEle.querySelectorAll(".jw-icon-fullscreen");
            if(videoEle && fullscreen[1]){
                fullscreen[1].style.display="none";
            }
        } catch (error) {
            console.log(error.name);
            console.log(error.message);
        }
    }
    const handelMenuBtns = (Player)=>{
        try {
            const videoEle = Player.getContainer();
            const rewind =  videoEle.querySelectorAll(".jw-icon-rewind");
            const slider =  videoEle.querySelectorAll(".jw-slider-horizontal");
            const duration =  videoEle.querySelectorAll(".jw-text-duration");
            const liveBtn =  videoEle.querySelectorAll(".jw-text-live");
            if(videoEle && rewind[1]){
                // rewind[1].style.display="none";
                console.log("CALLED");
            }
            if(videoEle && slider[0]){
                slider[0].style.pointerEvents="none";
            }
            if(videoEle && duration[0]){
                duration[0].style.display="none";
            }
            if(videoEle && liveBtn[0]){
                liveBtn[0].style.display="flex";
            }
        } catch (error) {
            console.log(error.name);
            console.log(error.message);
        }
    }
    const setUpVid = (video)=>{
        const Jplayer = playerInit();
        if(Jplayer)
        Jplayer.on({
            'ready':()=>{
                startVideo(video,Jplayer)
            },
            
        });
    }
    return (
        <div tabIndex="0" ref={(video=>setUpVid(video))} id={props.eventId}>
            <img alt="video" style={{width:'100%'}} src={`${props.image}`} tabIndex="0"/>
        </div>
    )
}
VideoPlayer.defaultProps = {
  isLive: false,
  autostart: false,
  hideControls: false,
  hideFullScreen: false,
  startVideo: () => {},
  file: "",
  thumbImageClickIndex: -1,
  setSelectedThumbImage: () => {}
};
VideoPlayer.propTypes = {
  file: PropTypes.string,
  startVideo: PropTypes.func,
  autostart: PropTypes.bool,
  isLive: PropTypes.bool,
  hideControls: PropTypes.bool,
  hideFullScreen: PropTypes.bool,
  thumbImageClickIndex: PropTypes.number,
  setSelectedThumbImage: () => {},
};

export {VideoPlayer}