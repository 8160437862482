import React from "react";
import { CircularProgress } from "@material-ui/core";
import { SpinnerConstants } from "../../utils/constants";

// var Cl_color=localStorage.getItem('clientcolor');

const ComponentLoader = (props)=>{
  const { size = SpinnerConstants.SIZE,thickness = SpinnerConstants.THICKNESS,pastDelay } = props;
  return (
    pastDelay ?  (<div style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
        <CircularProgress
            label="loader"
            aria-label="loader"
            size={size}
            thickness={thickness}
            color='secondary'
        />
      </div>) : null
  )
}


export default ComponentLoader