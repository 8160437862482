import React from "react";
import { Typography, Grid } from "@material-ui/core";
import { PropTypes } from "prop-types";
import { Translate } from "utils/Translate";
import Drawer from "../Drawer";
import { Skeleton } from "@material-ui/lab";
import { useState, useEffect } from "react";

function BannerComponent(props) {

  const [openDrawer,setOpenDrawer] = useState(false)
  const [hovered,setHovered] = useState(false)

  const handleDrawerOpen = () => {
    setOpenDrawer(true)
    setTimeout(() => {
      setOpenDrawer(false)
    }, 500);
  };

  useEffect(() => {
    handleMouseOut();
  }, [props.id]);

    const handleMouseOver = () => {
      setHovered(true);
    };

    const handleMouseOut = () => {
      setHovered(false);
    };

  const menuHeading = props.menuHeading
  return (
    <div
      className="fixedWidthContainer container main-margin"
      style={{
        backgroundImage: `url(${props.imageUrl})`,
      }}
    >
      <div className="category-overlay">
        <div className="category-banner ">
          <div className={`d-flex ${hovered && "highlight-hover"}`} onMouseOver={handleMouseOver} onMouseOut={handleMouseOut}>
            <Drawer children={props.drawer} drawerOpen={openDrawer} itemHovered={hovered}/>
            <Typography className="m-t-xs-5 browseCategory menu-heading makeTextClickable" onClick={handleDrawerOpen} >
              {Translate({
                id: `${menuHeading.context}.${menuHeading.title}`,
              })}
            </Typography>
          </div>
        </div>
        <div
          style={{ zIndex: 1 }}
          className="category-page-heading chnl-detail-desc text-white"
        >
          {!props.heading && (
            <>
              <Skeleton variant="text" width={"50%"} height={30} />
              <Skeleton variant="text" width={"70%"} height={60} />
            </>
          )}
          <div
            className={`d-flex ${props.bannerContainerClasses}`}
          >
            {props.titleImage ? (
              <img src={props.titleImage} className="channel-profileImg" alt="profile-imagge"/>
            ) : null}
            <Typography
              variant="h1"
              className={`m-t-xs-5 ${props.bannerHeadingClasses}`}
              data-testid="banner-title"
            >
              {props.heading}
            </Typography>
          </div>
          <Grid container direction="row">
            <Grid
              item
              lg={12}
              md={12}
              className="channelDescription category-desc"
            >
              <Typography
                data-testid="banner-desc"
                className="padding16 font-h6"
              >
                {props.subHeading}
              </Typography>
            </Grid>
          </Grid>
        </div>
        <div className="inner-banner-component">{props?.rightChild}</div>
      </div>
    </div>
  );
}
BannerComponent.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
};

export default BannerComponent;
