import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import IconBackArrow from "../../../CommonImages/icon-backArrow";
import { Button, Typography } from "@material-ui/core";
import { Translate } from "../../../utils/Translate";
import { PlayListInfoEditDelete } from "./PlayListInfoEditDelete";
import IconPlayListPlay from "../../../CommonImages/icon-playlist-play";
import Routes from "../../../components/Router/Routes";
import { CustomSwitch } from "./PlayListInfo.Style";
import { useNavigate } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import config from "../../../assets/config.json"

const PlayListInfoDetails = (props) => {
  const {
    updatePlayListTitle,
    loading,
    handelModal,
    modal,
    modalDelete,
    deletePlayList,
    deleteClass,
    selectedRow,
    title,
    description,
    playlistId,
    list,
  } = props;
  const [isLoop, setPlayListInfoData] = React.useState(
    !!JSON.parse(String(localStorage.getItem("isLoop")).toLowerCase()) ?? false
  );

  const [playBtnEnabled, setPlayBtnEnabled] = useState(false);
  const navigate = useNavigate();
  const handleChange = (event) => {
    localStorage.setItem("isLoop", event.target.checked);
    setPlayListInfoData(event.target.checked);
  };

  const backToPlaylist = () => {
    navigate(Routes.userPlayList);
  };

  const handleSubmit = () => {
    localStorage.setItem("isLoop", isLoop);
    navigate(Routes.playList + "/" + playlistId + "/" + isLoop, {
      state: { playlistId: playlistId },
    });
  };

  useEffect(() => {
    if (list && list?.length > 0) {
      const publishedClassFound = list.some((items) => items.isPlayable && items.isUnlocked);
      if (publishedClassFound) {
        setPlayBtnEnabled(false);
      } else {
        setPlayBtnEnabled(true);
      }
    }
  }, [list]);

  return (
    <div className="play-list-info-details">
      <div className="play-list-info-heading">
        <div
          onClick={backToPlaylist}
          className="align-left  play-list-info-heading-icon"
          role="button"
          aria-label="Back to playlist screen"
          tabindex="0"
        >
          <IconBackArrow
            id="play-list-info-back-arrow"
            color={config.primaryColor}
            role="button"
            aria-label="Back to playlist screen"
            tabindex="0"
          />
        </div>
        <div className="play-list-info-heading-title play-list-left-title">
          {loading ? (
            <Skeleton role="presentation" variant="rect" width={"100%"} height={50} />
          ) : (
            <span>{title}</span>
          )}
        </div>
        <div className="play-list-info-heading-controls play-list-right-heading">
          <div className="play-list-info-heading-text loop-font-class">
            <span>
              {Translate({
                id: "Playlist.loopPlayBack",
                defaultMessage: "Loop playback",
              })}
            </span>
            <CustomSwitch
              disabled={loading}
              name="isLoop"
              onChange={handleChange}
              value={isLoop}
              checked={isLoop}
              tabIndex='0'
              inputProps={{ "aria-label": "Loop" ,"tabIndex":"0"}}
            />
          </div>
          <div className="play-list-info-heading-btn">
            <Button
              variant="contained"
              color="secondary"
              className="play-list-info-btn btn-default"
              disabled={playBtnEnabled}
              onClick={handleSubmit}
              role="button"
              aria-label="Play playlist for details screen"
              tabindex="0"
            >
              <Typography variant="button"   tabindex="0" aria-label="Play playlist for details screen">
                {" "}
                {Translate({
                  id: "Playlist.Play",
                  defaultMessage: "Play",
                })}{" "}
              </Typography>
              <IconPlayListPlay id="play-icon-playlist"   tabindex="0"  aria-label="Play playlist for details screen" />
            </Button>
          </div>
        </div>
      </div>
      <div className="play-list-info-description playlist-details-description">
        {loading ? (
          <Skeleton role="presentation" variant="rect" width={"100%"} height={50} />
        ) : (
          description
        )}
      </div>
      <div className="play-list-info-actions">
        {!loading && (
          <PlayListInfoEditDelete
            {...{
              title,
              description,
              savePlayList: updatePlayListTitle,
              loading,
              handelModal,
              modal,
              modalDelete,
              deletePlayList,
              deleteClass,
              selectedRow,
            }}
          />
        )}
      </div>
    </div>
  );
};

PlayListInfoDetails.defaultProps = {
  updatePlayListTitle: () => {},
  handelModal: () => {},
  deleteClass: () => {},
  deletePlayList: () => {},
  loading: false,
  modal: false,
  modalDelete: false,
  title: "",
  description: "",
  list: [],
};
PlayListInfoDetails.propTypes = {
  updatePlayListTitle: PropTypes.func,
  handelModal: PropTypes.func,
  deletePlayList: PropTypes.func,
  deleteClass: PropTypes.func,
  loading: PropTypes.bool,
  modal: PropTypes.bool,
  modalDelete: PropTypes.bool,
  title: PropTypes.string,
  description: PropTypes.string,
  list: PropTypes.array,
};

export { PlayListInfoDetails };
