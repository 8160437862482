import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Translate } from 'utils/Translate';

const ResetPasswordLinkExpired = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/user/forget/0")
    }

    return (
        <div className='password-link-expire-container'>
            <h1>{Translate({ id:"ForgetPassword.LinkExpire"})}</h1>
            <h2>{Translate({ id: "ForgetPassword.LinkExpireMessage" })}</h2>
            <a className="dynamiclinks makeTextClickable" onClick={handleClick} role='button' aria-label="Forget Password">{Translate({ id:"login.ForgotPassword"})}</a>
        </div>
    );
};

export default ResetPasswordLinkExpired;