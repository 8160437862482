import React, { useEffect, useReducer }from "react";
import { Button, TextField } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { Star } from "../../../../CommonImages/star";
import { Translate } from "../../../../utils/Translate";
import { useRatingReviews } from "../../../../hooks/useRatingReviews";
import { Skeleton } from "@material-ui/lab";
import { useState } from "react";

const StarRating = (props) => {
  const { useRatingReviewsState, useRatingReviewsDispatch, myReviewReducer } = useRatingReviews();
  const { userClassRating } = useRatingReviewsState();
  const { updateUserRating } = useRatingReviewsDispatch();
  const {initialState,useMyReviewReducer,setReducerData} = myReviewReducer
  const [ratingReviewsState,myReviewDispatch]=useReducer(useMyReviewReducer,initialState)
  const {rating,hover,comment,shrink } = ratingReviewsState
  const config = require("../../../../assets/config.json")

    useEffect(()=>{
      setReducerData(myReviewDispatch,{comment:userClassRating?.reviewComment,rating:userClassRating?.rating})
    },[userClassRating?.rating])

    const onMouseIn = (ratingValue) =>{
      setReducerData(myReviewDispatch,{hover:ratingValue})
    }

    const onMouseOut = () =>{
      setReducerData(myReviewDispatch,{hover:null})
    }

    const buttonDisabled =()=>{
      if(userClassRating?.rating === rating && userClassRating?.reviewComment === comment){
        return true
      }else{
        if(rating ===0) {return true}
        else{return false}
      }
    }
    
    return (
      userClassRating ?<div>
          <div>
            {[...Array(5)].map((star,i) => {
                const ratingValue = i+1;
                return (
                    <label>
                        <input type="radio" name="rating" value={ratingValue} 
                        onClick={()=> setReducerData(myReviewDispatch,{rating:ratingValue})}
                        role='button'
                        aria-label='Set rating'
                        tabIndex="0"
                        />
                        <Star
                        id={ratingValue}
                        mouseIn={()=>onMouseIn(ratingValue)}
                        mouseOut={()=>onMouseOut()}
                      color={config.primaryColor}
                      fill={ratingValue <= (hover || rating) ? true : false}
                      role='button'
                      aria-label='Set rating'
                      tabIndex="0"
                        />
                    </label>)
            })}
          </div>
            <TextField
            id="standard-multiline-static"
            label={Translate({ id: "RatingsReviews.Comment" })}
            multiline rows={5}
            variant="standard"
            value={comment}
            onClick={()=>setReducerData(myReviewDispatch,{shrink:true})}
            onChange={(e) => {setReducerData(myReviewDispatch,{comment:(e.target.value)}) }}
            className="review-comment-box"
            inputProps={{ maxLength: 500,className: "reviews-comment text-gray" }}
            InputLabelProps={{ shrink: (comment !==""|| shrink) ? true : false}}
            />
            <Button
              type="button"
              variant="contained"
              color="secondary" className="m-t-xs-15 btn-mrgn"
              onClick={() => {
                updateUserRating(props.classId,rating,comment)
                props.handleDialogClose()
              }}
              disabled={buttonDisabled()}
              role='button'
              aria-label='Save'
              tabIndex="0"
            >
              <Typography variant="button" role='button' aria-label='Save'>
                {Translate({ id: "Subscription.Save" })}
              </Typography>
            </Button>
        </div>: 
        <div>
          <div>
                 <div>
                  <Skeleton role="presentation" variant="rect" width={'50%'} height={30}  className="rating-shimmer"/>
                  <Skeleton role="presentation" variant="rect" width={'80%'} height={100} className="rating-shimmer"/>
                  <Skeleton role="presentation" variant="rect" width={'80%'} height={5} className="rating-shimmer"/>
                  </div>
                </div>
        </div>
    );
};
export default StarRating;