import React from 'react'
import PropTypes from 'prop-types'
import { Skeleton } from '@material-ui/lab';

const PlayListInfoShimmer = (props) => {
    const items = [...Array(5)];
  return (
    <div>
        {items.map((_,index) =>( <div className='playlist-page-shimmer' key={index}>
                        <div className="playlist-page-shimmer-item">
                          <Skeleton role="presentation" variant="rect" width={'100%'} height={150} />
                        </div>
                        {index < 4 ? <div className="line"></div> : null}
                    </div>
                  )
        )}
    </div>
  )
}

PlayListInfoShimmer.propTypes = {

}

export { PlayListInfoShimmer }