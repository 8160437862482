import { LocalyticsProfileAttributesName } from "./LocalyticsConstants";
import {
  setProfileAttribute,
  setCustomDimension,
  setCustomerId,
  setCustomerEmail,
  LocalyticsLogger,
} from "./Localytics";
import { isEmptyOrNullString } from "utils/utilityfunctions";

/// Profile Attributes
export function setFirstName(firstName) {
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.FIRST_NAME,
    value: firstName,
  });
}

export function setLastName(lastName) {
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.LAST_NAME,
    value: lastName,
  });
}

export function setAuthenticated(authenticated) {
  setCustomDimension(1, authenticated ? "1" : "0");
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.AUTHENTICATED,
    value: authenticated ? "True" : "False",
  });
}

/// Access Code - UNKNOWN or any string value
/// Discount for SUB/FAC
export function setAccessCode(accessCode) {
  setCustomDimension(8, accessCode ? accessCode : "UNKNOWN");
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.ACCESS_CODE,
    value: accessCode ? accessCode : "UNKNOWN",
  });
}

export function setExternalId(externalId) {
  setCustomDimension(9, externalId);
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.EXTERNAL_ID,
    value: externalId,
  });
}

///UNKNOWN/INACTIVE/ACTIVE
export function setSubscriberStatus(status) {
  const value = status ? status : "UNKNOWN";
  setCustomDimension(2, value);
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.SUBSCRIBER_STATUS,
    value: value,
  });
}

export function setSubscriberStartDate(date) {
  if (date) {
    const dateObj = new Date(date);
    if (dateObj) {
      setCustomDimension(4, dateObj);
      setProfileAttribute({
        name: LocalyticsProfileAttributesName.SUBSCRIPTION_START_DATE,
        value: dateObj,
      });
    }
  }  
}

export function setSubscriberEndDate(date) {
  if (date) {
    const dateObj = new Date(date);
    if (dateObj) {
      setCustomDimension(5, dateObj);
      setProfileAttribute({
        name: LocalyticsProfileAttributesName.SUBSCRIPTION_END_DATE,
        value: dateObj,
      });
    }
  }    
}

export function setSubscriberInterval(value) {
  setCustomDimension(6, `${value}`);
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.SUBSCRIPTION_INTERVAL,
    value: value,
  });
}

export function setSubscriberIntervalCount(value) {
  setCustomDimension(7, `${value}`);
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.SUBSCRIPTION_INTERVAL_COUNT,
    value: value,
  });
}

///SSO/FAC/Trial/Paid
export function setSubscriptionType(value) {
  setCustomDimension(3, value);
  setProfileAttribute({
    name: LocalyticsProfileAttributesName.SUBSCRIPTION_TYPE,
    value: value,
  });
}

/// Login Success
export function setUserAuthenticated({ userId, email, tenantId, externalId }) {
  setCustomerId(userId);

  setCustomDimension(0, tenantId);
  setAuthenticated(true);

  if (isEmptyOrNullString(email) === false) {
    setCustomerEmail(email);
  }

  if (isEmptyOrNullString(externalId) === false) {
    setExternalId(externalId);
  }  
}

export function setUserData({firstName, lastName, email}) {
  LocalyticsLogger.log("Localytics:  setUserData ", firstName, lastName);
  setFirstName(firstName);
  setLastName(lastName);
  if (isEmptyOrNullString(email) === false) {
    setCustomerEmail(email);
  }
}

/// Logout
export function logoutUser() {
  LocalyticsLogger.log("logoutUser")
  setCustomerId("");
  setCustomerEmail("");

  setFirstName("")
  setLastName("")  
  
  const dateObj = new Date("0001").toDateString()

  // Custom Dimensions

  // 1
  setAuthenticated(false);

  // 2
  setSubscriberStatus("UNKNOWN");

  //3
  setSubscriptionType("UNKNOWN")  

  //4
  setSubscriberStartDate(dateObj)

  //5
  setSubscriberEndDate(dateObj)

  //6
  setSubscriberInterval("UNKNOWN")

  //7
  setSubscriberIntervalCount(0)

  //8
  setAccessCode("UNKNOWN")

  //9
  setExternalId("UNKNOWN")
}
