import React, { createContext, useContext, useEffect, useState } from "react";
import {SwipeableDrawer} from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import "../components/Drawers/Drawer.scss"


const DrawerContext = createContext();

const Drawer = (props) => {
    const { children, drawerOpen, itemHovered } = props;
    const [open, setOpen] = useState(false);
    const [hovered, setHovered] = useState(false);

    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };   
    
    useEffect(() => {
        if(drawerOpen) setOpen(drawerOpen);
    }, [drawerOpen]);

    useEffect(() => {
        if (itemHovered) setHovered(true);
        else setHovered(false);
    }, [itemHovered]);



return (
    <DrawerContext.Provider value={{ handleDrawerOpen, handleDrawerClose }}>
    <>
            <Menu data-testid="hamburger-menu" htmlColor="white" fontSize='large' onClick={handleDrawerOpen} className={`makeTextClickable menu-icon ${hovered && "highlight-hover"}`} />
    <SwipeableDrawer
    anchor="left"
    open={open}
    onClose={handleDrawerClose}
    onOpen={handleDrawerOpen}
    >
   <span data-testid="drawer-render">{children}</span>  
    </SwipeableDrawer>
    </>
    </DrawerContext.Provider>
)}

// A custom hook to use the functions in child components
export const useDrawer = () => useContext(DrawerContext);

export default Drawer