import { useDispatch, useSelector } from "react-redux";
import { setselectedFilters, setKeywords, setDuration, setSkillLevel, setFocusArea, setEquipmentsTypes, setIntensityLevel, setClassLanguage, setCategories, setSort, setFilterSearchText, resetFilters, setMediaType } from '../store/actions/filter';
import { setselectedFiltersOnDemand, setKeywordsOnDemand, setDurationOnDemand, setSkillLevelOnDemand, setFocusAreaOnDemand, setEquipmentsTypesOnDemand, setIntensityLevelOnDemand, setClassLanguageOnDemand, setCategoriesOnDemand, setSortOnDemand, setFilterSearchTextOnDemand, resetFiltersOnDemand, setOnDemandMediaType } from '../store/actions/filterOnDemand';
import { getTranslatedFile } from "../utils/utilityfunctions";
import { resetFiltersChannel, setChannelMediaType, setClassLanguageChannel, setDurationChannel, setEquipmentsTypesChannel, setFilterSearchTextChannel, setFocusAreaChannel, setIntensityLevelChannel, setKeywordsChannel, setSkillLevelChannel, setSortChannel, setselectedFiltersChannel } from "store/actions/filterChannel";

const useFilterDrawer = () => {
    const dispatch = useDispatch();

    const filterType = (showFLLOnDemand,channel) => {
        let filterInUse
        if (showFLLOnDemand) filterInUse = "filterOnDemand" ;
        if (channel) filterInUse = "filterChannel";
        else filterInUse = "filter"
        return filterInUse
    }
   
    const useFilterDrawerState = (props) => {
        const filterInUse = filterType(props.showFLLOnDemand, props.showChannelFilter)
        return(
        {
        loading: useSelector(state => state[filterInUse].loading),
        selectedFilters: useSelector(state => state[filterInUse].selectedFilters),
        filteredSkill: useSelector(state => state[filterInUse].filteredSkill),
        filteredDuration: useSelector(state => state[filterInUse].filteredDuration),
        filteredKeywords: useSelector(state => state[filterInUse].filteredKeywords),
        filteredSubCategory: useSelector(state => state[filterInUse].filteredSubCategory),
        filteredFocusArea: useSelector(state => state[filterInUse].filteredFocusArea),
        filteredEquipments: useSelector(state => state[filterInUse].filteredEquipments),
        filterSortBy: useSelector(state => state[filterInUse].filterSortBy),
        filterSearchText: useSelector(state => state[filterInUse].filterSearchText),
        filteredIntensity: useSelector(state => state[filterInUse].filteredIntensity),
        filteredLanguage: useSelector(state => state[filterInUse].filteredLanguage),
        filteredMediaType: useSelector(state => state[filterInUse].filteredMediaType),
        metadataEntitlement: useSelector(state => state.tenantConfig?.entitlementResponse[0]?.metaData)
    })}
    const useFilterDrawerDispatch = () => ({
        setOnDemandSelectedFilter: (tempFilters) => {
            dispatch(setselectedFiltersOnDemand(tempFilters))
        },
        setChannelSelectedFilter: (tempFilters) => {
            dispatch(setselectedFiltersChannel(tempFilters))
        },
        setSelectedFilters: (tempFilters) => {
            dispatch(setselectedFilters(tempFilters))
        },
        setOnDemandKeywords: (filterValue) => {
            dispatch(setKeywordsOnDemand(filterValue))
        },
        setChannelKeywords: (filterValue) => {
            dispatch(setKeywordsChannel(filterValue))
        },
        setKeywords: (filterValue) => {
            dispatch(setKeywords(filterValue));
        },
        setOnDemandDuration: (filterValue) => {
            dispatch(setDurationOnDemand(filterValue))
        },
        setChannelDuration: (filterValue) => {
            dispatch(setDurationChannel(filterValue))
        },
        setDuration: (filterValue) => {
            dispatch(setDuration(filterValue));
        },
        setOnDemandSkillLevel: (filterValue) => {
            dispatch(setSkillLevelOnDemand(filterValue))
        },
        setChannelSkillLevel: (filterValue) => {
            dispatch(setSkillLevelChannel(filterValue))
        },
        setSkillLevel: (filterValue) => {
            dispatch(setSkillLevel(filterValue));
        },
        setOnDemandFocusArea: (filterValue, filterTag) => {
            dispatch(setFocusAreaOnDemand([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setChannelFocusArea: (filterValue, filterTag) => {
            dispatch(setFocusAreaChannel([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setFocusArea: (filterValue, filterTag) => {
            dispatch(setFocusArea([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]));
        },
        setOnDemandEquipmentsTypes: (filterValue, filterTag) => {
            dispatch(setEquipmentsTypesOnDemand([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setChannelEquipmentsTypes: (filterValue, filterTag) => {
            dispatch(setEquipmentsTypesChannel([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setEquipmentsTypes: (filterValue, filterTag) => {
            dispatch(setEquipmentsTypes([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]));
        },
        setOnDemandIntensityLevel: (filterValue) => {
            dispatch(setIntensityLevelOnDemand(filterValue))
        },
        setChannelIntensityLevel: (filterValue) => {
            dispatch(setIntensityLevelChannel(filterValue))
        },
        setIntensityLevel: (filterValue) => {
            dispatch(setIntensityLevel(filterValue));
        },
        setOnDemandClassLanguage: (filterValue, filterTag) => {
            dispatch(setClassLanguageOnDemand([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setChannelClassLanguage: (filterValue, filterTag) => {
            dispatch(setClassLanguageChannel([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]))
        },
        setClassLanguage: (filterValue, filterTag) => {
            dispatch(setClassLanguage([{
                'filterValue': filterValue,
                'filterTag': filterTag
            }]));
        },
        setOnDemandCategories: (filterValue) => {
            dispatch(setCategoriesOnDemand(filterValue))
        },
        setChannelCategories: (filterValue) => {
            dispatch(setCategoriesChannel(filterValue))
        },
        setCategories: (filterValue) => {
            dispatch(setCategories(filterValue));
        },
        setOnDemandSort: (filterValue) => {
            dispatch(setSortOnDemand(filterValue))
        },
        setChannelSort: (filterValue) => {
            dispatch(setSortChannel(filterValue))
        },
        setSort: (filterValue) => {
            dispatch(setSort(filterValue));
        },
        setFilterSearchText: (searchText) => {
            dispatch(setFilterSearchText(searchText))
        },
        setOnDemandFilterSearchText: (searchText) => {
            dispatch(setFilterSearchTextOnDemand(searchText))
        },
        setChannelFilterSearchText: (searchText) => {
            dispatch(setFilterSearchTextChannel(searchText))
        },
        setOnDemandMediaType: (filterValue) => {
            dispatch(setOnDemandMediaType(filterValue))
        },
        setChannelMediaType: (filterValue) => {
            dispatch(setChannelMediaType(filterValue))
        },
        setMediaType: (filterValue) => {
            dispatch(setMediaType(filterValue))
        },
        resetOnDemandFilters: () => {
            dispatch(resetFiltersOnDemand());
        },
        resetChannelFilters: () => {
            dispatch(resetFiltersChannel());
        },
        resetFilters: () => {
            dispatch(resetFilters());
        },
    })


    const initialState = {
        clearSearch : "none",
        skillValue  : [],
        intensityValue : [],
        levelText : "",
        level : "",
        intensityText : "",
        intensity:"",
        searchText : "" ,
        keyWordPressed:false,
        expanded: false,
        isIOS : false,
        isAndroid: false,
        translatedFile:getTranslatedFile(),
        mediaType:""
    };

    const useFilterDrawerAction = {
        UPDATE_FILTERDRAWER: "UPDATE_FILTERDRAWER",
    };

    const useFilterDrawerReducer = (state, action) => {
        const { type, payload } = action;
        switch (type) {
           
            case useFilterDrawerAction.UPDATE_FILTERDRAWER:
                return { ...state, ...payload };

            default:
                return state
        }
    };

    const setReducerData = (filterDrawerDispatch,payload)=>{
        filterDrawerDispatch({
            type:useFilterDrawerAction.UPDATE_FILTERDRAWER,
            payload:payload
        })
    }




    return { 
        useFilterDrawerState,
        useFilterDrawerDispatch,
        filterDrawerReducer : {useFilterDrawerReducer ,useFilterDrawerAction , initialState, setReducerData }
    }
}
export { useFilterDrawer }