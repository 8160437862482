import {
  LocalyticsAttributesName,
  LocalyticsEventName,
} from "./LocalyticsConstants";
import { logDetailsInLocalytics } from "./Localytics";

export function logUserLogin({ userId, clientId }) {
  const attributes = {
    [LocalyticsAttributesName.CLIENT_ID]: clientId,
    [LocalyticsAttributesName.USER_ID]: userId,
  };
  logDetailsInLocalytics(LocalyticsEventName.USER_LOGIN, attributes);
}

/// Channel Viewed
export function logChannelViewed({ channelId, channelName, providerId }) {
  const attributes = {
    [LocalyticsAttributesName.CHANNEL_ID]: channelId,
    [LocalyticsAttributesName.CHANNEL_Name]: channelName,
    [LocalyticsAttributesName.PROVIDER_ID]: providerId,
  };
  logDetailsInLocalytics(LocalyticsEventName.CHANNEL_VIEWED, attributes);
}

/// Category Viewed
export function logCategoryViewed({ categoryId, categoryName }) {
  const attributes = {
    [LocalyticsAttributesName.CATEGORY_ID]: categoryId,
    [LocalyticsAttributesName.CATEGORY_Name]: categoryName,
  };
  logDetailsInLocalytics(LocalyticsEventName.CATEGORY_VIEWED, attributes);
}

/// On Demand Class Viewed
export function logODClassViewed({
  classId,
  className,
  classDuration,
  category,
  providerId,
  providerName,
  channelId,
}) {
  const attributes = {
    [LocalyticsAttributesName.CLASS_ID]: classId,
    [LocalyticsAttributesName.CLASS_NAME]: className,
    [LocalyticsAttributesName.CLASS_DURATION]: classDuration,
    [LocalyticsAttributesName.CLASS_CATEGORY]: category,
    [LocalyticsAttributesName.PROVIDER_ID]: providerId,
    [LocalyticsAttributesName.PROVIDER_NAME]: providerName,
    [LocalyticsAttributesName.CHANNEL_ID]: channelId,
  };
  logDetailsInLocalytics(
    LocalyticsEventName.ON_DEMAND_CLASS_VIEWED,
    attributes
  );
}

/// On Demand Class Started
export function logODClassStarted({
  classId,
  className,
  classDuration,
  category,
  providerId,
  providerName,
  channelId,
}) {
  const attributes = {
    [LocalyticsAttributesName.CLASS_ID]: classId,
    [LocalyticsAttributesName.CLASS_NAME]: className,
    [LocalyticsAttributesName.CLASS_DURATION]: classDuration,
    [LocalyticsAttributesName.CLASS_CATEGORY]: category,
    [LocalyticsAttributesName.PROVIDER_ID]: providerId,
    [LocalyticsAttributesName.PROVIDER_NAME]: providerName,
    [LocalyticsAttributesName.CHANNEL_ID]: channelId,
  };
  logDetailsInLocalytics(
    LocalyticsEventName.ON_DEMAND_CLASS_STARTED,
    attributes
  );
}

/// On Demand Class Performed
export function logODClassPerformed({
  classId,
  className,
  classDuration,
  playDuration,
  classDurationSeconds,
  playDurationSeconds,
  category,
  providerId,
  providerName,
  channelId,
}) {
  const attributes = {
    [LocalyticsAttributesName.CLASS_ID]: classId,
    [LocalyticsAttributesName.CLASS_NAME]: className,
    [LocalyticsAttributesName.CLASS_DURATION]: classDuration,
    [LocalyticsAttributesName.PLAY_DURATION]: playDuration,
    [LocalyticsAttributesName.CLASS_DURATION_SECONDS]: classDurationSeconds,
    [LocalyticsAttributesName.PLAY_DURATION_SECONDS]: playDurationSeconds,
    [LocalyticsAttributesName.CLASS_CATEGORY]: category,
    [LocalyticsAttributesName.PROVIDER_ID]: providerId,
    [LocalyticsAttributesName.PROVIDER_NAME]: providerName,
    [LocalyticsAttributesName.CHANNEL_ID]: channelId,
  };
  logDetailsInLocalytics(
    LocalyticsEventName.ON_DEMAND_CLASS_PERFORMED,
    attributes
  );
}

/// Live Connect Class Viewed
export function logLiveConnectClassViewed({
  classId,
  live,
  className,
  startDate,
  eventId,
  eventType,
}) {
  let dateObj = null;
  if (startDate) {
    dateObj = new Date(startDate);
  }  
  const attributes = {
    [LocalyticsAttributesName.CLASS_ID]: classId,
    [LocalyticsAttributesName.LIVE]: live,
    [LocalyticsAttributesName.CLASS_NAME]: className,
    [LocalyticsAttributesName.START_DATE]: dateObj,
    [LocalyticsAttributesName.EVENT_ID]: eventId,
    [LocalyticsAttributesName.EVENT_TYPE]: eventType,
  };
  logDetailsInLocalytics(
    LocalyticsEventName.LIVE_CONNECT_CLASS_VIEWED,
    attributes
  );
}

/// Live Connect Class Started
export function logLiveConnectClassStarted({
  classId,
  live,
  className,
  startDate,
  eventId,
  classDuration,
  eventType,
}) {
  let dateObj = null;
  if (startDate) {
    dateObj = new Date(startDate);
  }  
  const attributes = {
    [LocalyticsAttributesName.CLASS_ID]: classId,
    [LocalyticsAttributesName.LIVE]: live,
    [LocalyticsAttributesName.CLASS_NAME]: className,
    [LocalyticsAttributesName.START_DATE]: dateObj,
    [LocalyticsAttributesName.EVENT_ID]: eventId,
    [LocalyticsAttributesName.CLASS_DURATION]: classDuration,
    [LocalyticsAttributesName.EVENT_TYPE]: eventType,
  };
  logDetailsInLocalytics(
    LocalyticsEventName.LIVE_CONNECT_CLASS_STARTED,
    attributes
  );
}

/// Live Connect Class Performed
export function logLiveConnectClassPerformed({
  classId,
  live,
  className,
  startDate,
  eventId,
  playDuration,
  playDurationSeconds,
  classDurationSeconds,
  classDuration,
  eventType,
}) {
  let dateObj = null;
  if (startDate) {
    dateObj = new Date(startDate);
  }  
  const attributes = {
    [LocalyticsAttributesName.CLASS_ID]: classId,
    [LocalyticsAttributesName.LIVE]: live,
    [LocalyticsAttributesName.CLASS_NAME]: className,
    [LocalyticsAttributesName.START_DATE]: dateObj,
    [LocalyticsAttributesName.EVENT_ID]: eventId,
    [LocalyticsAttributesName.PLAY_DURATION]: playDuration,
    [LocalyticsAttributesName.PLAY_DURATION_SECONDS]: playDurationSeconds,
    [LocalyticsAttributesName.CLASS_DURATION_SECONDS]: classDurationSeconds,
    [LocalyticsAttributesName.CLASS_DURATION]: classDuration,
    [LocalyticsAttributesName.EVENT_TYPE]: eventType,
  };
  logDetailsInLocalytics(
    LocalyticsEventName.LIVE_CONNECT_CLASS_PERFORMED,
    attributes
  );
}
