import React from "react";
import Grid from "@material-ui/core/Grid";
import {  Typography } from "@material-ui/core";
import { Translate } from "../../utils/Translate";
import ChannelsCardWrapper from "./ChannelsCardWarpper";
import Spinner from "../../components/spinner/spinner";
import CustomGrid from "../CustomGrid/CustomGrid";
import CarouselSliderComponent from "../../components/carousel/CarouselSliderComponent";
import Routes from "components/Router/Routes";
import { CarouselShimmer } from "components/carousel/CarouselShimmer";
class ChannelsCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      channels: [],
      isloading: true,
    };
  }

  componentDidMount() {
   this.handelMount();
   this.props.clearClassData();
  }
  componentDidUpdate(prevProps) {
    if (prevProps.allChannels !== this.props.allChannels) {
      this.updateChannelsState();
    }
  }
  handelMount(){
    window.scrollTo(0, 0);
    if (this.props?.allChannels === null || this.props?.allChannels?.length === 0) {
      this.props.fetchAllChannels();
    } else {
      this.setState({ channels: this.props?.allChannels,isloading:false });
    }
  }
  updateChannelsState = () => {
    if (this.props.allChannels && this.props.allChannels?.length > 0) {
      this.setState({ channels: this.props?.allChannels,isloading:false });
    }
  };

  ViewChannel = () => {
    this.props.navigate(
      "/channelView",
      {state: { clubClasses: this.props.allChannels }});
  };

  onClickHandler = (tag) => {
    this.props.navigate(Routes.channelDetailView + tag)
  }

  carousalData = (channels) => {
    const caroselItemCount = 5;
    const extractedData = channels?.slice(0, caroselItemCount)?.map(channel => (
      {
      name: channel?.displayName?channel?.displayName:channel?.name,
      description: channel.description,
      image: channel.mediaSpaceImageUrl,
      profileImage:channel?.profileImageUrl,
      tag: channel.tag,
      path: "/channels",
      visit: Translate({ id: "ClassListViews.VisitChannel" }),
    }));
    return extractedData
  }

  render() {
    let {isloading,channels} =this.state;

    return (
      <div className="main-margin">
        <div className="fixedWidthContainer container">
          <div data-testid="carousel-container">
            {channels &&
              channels.length > 0 ?
              <CarouselSliderComponent props={this.carousalData(channels)}  handleClick={this.onClickHandler}  /> : (
            <CarouselShimmer/>
            )}
          </div>       
        </div>
        <div className="page-container">
        <Typography variant="h1" className="channel-heading">
            {Translate({ id: "Channels.AllChannels"})}
        </Typography>   
        </div>
          <CustomGrid
            items={this.state?.channels}
            totalItems={this.state?.channels?.length}
            showLoading={isloading}
            classes={isloading ? 'cardList' : 'cardList no-hidden'}
            containerClass="m-bt-lg-30 m-t-xs-20 channelSection list-container-gap"
            loader={<Spinner backColor="white" />}
            listClass={'list list-live-page channel-list'}
            >
           { (data => <ChannelsCardWrapper data={data} cardType="channel"/>)}
          </CustomGrid>
        </div>

    );
  }
}

export default ChannelsCollection;
