import React from 'react';
import { Translate } from 'utils/Translate'
import MySubscriptionList from './MySubscriptionList';
import { useSelector } from 'react-redux';
import { isNonEmptyArrayOrObject } from 'utils/utilityfunctions';
import { useNavigate } from 'react-router';
import Routes from 'components/Router/Routes';
import IconRigntArrow from 'CommonImages/icon-rightArrow';
import config from "../../assets/config.json"
import { useMySubscriptionList } from 'hooks/useMySubscriptionList';

const MySubscription = function MySubscription(props) {
    
    const navigate = useNavigate()
    const { useMySubscriptionListState } = useMySubscriptionList();
    const { mySubscriptions } = useMySubscriptionListState();

    const showDescription = isNonEmptyArrayOrObject(mySubscriptions?.data);

    const handleClick = () => {
        navigate(Routes.mySubscriptionsRoute);
    }
    return (
      <React.Fragment>
        <div className="m-t-30">
          <div>
            <div className="align-left d-flex m-b-7 makeTextClickable" onClick={handleClick} role='button' aria-label='My Releases' tabIndex="0">
              <h1 className="align-left font-h1 clear makeTextClickable" tabIndex="0">
                {Translate({
                  id: "homePage.MySubscription",
                  defaultMessage: "My Releases",
                })}
              </h1>
              <IconRigntArrow id="arrow-2" color={config.primaryColor} cls={"icon-arrow-home"}/>
            </div>
            {showDescription ? (
              <h6 className="text-gray font-h6 align-left ">
                {Translate({
                  id: "SubscriptionBundle.AccessAllReleases",
                  defaultMessage:
                    "Instantly access the content in your subscriptions",
                })}
              </h6>
            ) : null}
          </div>
          {<MySubscriptionList />}
        </div>
      </React.Fragment>
    );
};



export default MySubscription;

