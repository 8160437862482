import React from "react";
import "./404.css";
import { Button } from "@material-ui/core";
import { Translate } from '../../utils/Translate';
import Routes from '../Router/Routes'
import { IconAlertError } from "../../CommonImages/icon-alert";
import { useNavigate } from "react-router";

const NotFound = () => {
    const navigate = useNavigate();
    return (
      <div className="page-container not-found">
        <h1 className="MuiTypography-root MuiTypography-h1 err-header m-t-15 m-t-xl-err">
        {Translate({id: "Error.404"})}
        </h1>
        <h6 className="MuiTypography-root MuiTypography-h6 err-subtext text-gray subscriptionTagline">
        {Translate({id: "Error.NotFound"})}
           </h6>
        <IconAlertError className="not-found-icon" />
        <Button fullWidth className="m-t-40 m-t-xs-15 MuiButtonBase-root MuiButton-root MuiButton-contained MuiButton-containedSecondary btn-default"
          onClick={() => { navigate(Routes.homePage) }} tabIndex='0' aria-label='Home page' role='button'
        >
          <span className="MuiTypography-root MuiTypography-button">
          {Translate({ id: "Error.ExploreClasses" })}
          </span>
          </Button>
      </div>
    );
}
export default NotFound;
