import React from "react"

function Close(props){
    const color = props.color
    return (
      <div className={`icon-svg ${props.cls}`} onClick={props.onClick} role='button' aria-label='close' tabIndex='0'>
        <svg
          className="icon-svg-play-list"
          id={props.id}
          xmlns="http://www.w3.org/2000/svg"
          viewBox={`0 0 32 32`}
        >
          <defs>
            <path
              d="M16 0c8.837 0 16 7.163 16 16s-7.163 16-16 16S0 24.837 0 16 7.163 0 16 0z"
              id="8vy2eejnsa"
            />
            <path
              d="M15.25 4.758a.83.83 0 0 0-1.175 0L10 8.825 5.925 4.75A.83.83 0 1 0 4.75 5.925L8.825 10 4.75 14.075a.83.83 0 1 0 1.175 1.175L10 11.175l4.075 4.075a.83.83 0 1 0 1.175-1.175L11.175 10l4.075-4.075a.835.835 0 0 0 0-1.167z"
              id="jo0f0y9gec"
            />
          </defs>
          <g fill="none" fill-rule="evenodd">
            <g>
              <use xlinkHref="#8vy2eejnsa" fill={color} />
            </g>
            <g transform="translate(6 6)">
              <use xlinkHref="#jo0f0y9gec" fill="#FFF" />
            </g>
          </g>
        </svg>
      </div>
    );
}

export default Close
